import { DUMMY_STORE } from "../onboarding/stepsSelectors";
import types from "../types";

const initialState = {
  businessName: "",
  businessID: "",
  clappCredits: 0,
  currency: "",
  regulatoryDocument: "",
  averageTicket: "",
  socialNetwork: "",
  secondSocialNetwork: "",
  trafficURL: "",
  costCollaboration: "",
  shortDescription: "",
  adminPhone: "",
  generalDiscountCode: "",
  generalDiscountValue: "",
  reservationURL: "",
  websiteURL: "",
  lastCommentInvite: "",
  lastTypeContent: {
    images: false,
    videos: false,
  },
  lastDateInvite: new Date().getTime(),
  blockedLastInviteDate: null,
  featureFlags: {
    isTrainedPhysicalBrand: false,
    isCampaignMode: false,
  },
  justoURL: "",
  shopifyURL: "",
  statisticsCloudTaskID: false,
  productsAvailability: "local",
  cities: [],
  showSocialFeedGenerator: false,
  isActive: false,
  isLoggedFirstTime: false,
  isActiveFirstTime: false,
  isFreeTrial: null,
  category: "",
  minProductValue: "",
  subCategories: [],
  ordersMethods: {
    delivery: false,
    inStore: false,
    justo: false,
    shopify: false,
  },
  logoGenerateContent: "",
  logoImg: "",
  coverImg: "",
  subscriptionPlan: "",
  hasBillingCycle: false,
  hasBillingCycleTask: false,
  hasAdvancedCampaignPayments: true,
  thirdPartyCredentials: {
    msgUrbanos: {
      userID: "",
      clientID: "",
      clientSecretID: "",
      companyID: "",
    },
  },
  lastInviteConfiguration: {},
  legalRepresentative: {
    businessName: "",
    firstname: "",
    lastname: "",
    identification: "",
    typeId: "",
    phone: "",
  },
  parentShopID: "",
  profileInfluencer: {
    cloudTaskID: "",
    categories: [],
    gender: {
      male: false,
      female: false,
    },
    ageRange: [],
    audienceGender: {
      male: false,
      female: false,
    },
    audienceAgeRange: [],
    cities: [],
    bodyBuild: [],
    clothingStyle: [],
    distinguishingFeatures: [],
    contentStyle: [],
    engagementRange: 2,
    numberInvitations: null,
    budgetInvitations: null,
    platforms: {
      tiktok: false,
      instagram: false,
      youtube: false,
    },
    formats: {
      instagramBurstStories: false,
      instagramPost: false,
      instagramReel: false,
      instagramStory: false,
      tiktokPost: false,
      tiktokStory: false,
    },
    comment: "",
    terms: "",
    audienceRange: 20000,
    autoInvitationsIsActive: false,
    hasCloudTask: false,
    contentReference: "",
    typeContent: {
      images: false,
      videos: true,
    },
  },
  billing: {
    address: "",
    city: "",
    province: "",
    email: "",
    firstName: "",
    lastName: "",
    numId: "",
    phone: "",
    typeId: "",
    typePerson: "",
  },
  schedule: [
    { day: "Lunes" },
    { day: "Martes" },
    { day: "Miércoles" },
    { day: "Jueves" },
    { day: "Viernes" },
    { day: "Sábado" },
    { day: "Domingo" },
  ],
  signUpCountry: "co",
  stores: {},
  integrations: {},
  users: {},
  contacts: {},
  currentMenu: [],
  isLoading: true,
  shops: {},
  registerShops: {},
  registerShopsAccepted: {},
  staff: [],
  feedbacks: {},
};

export const shopsReducer = (state = initialState, action) => {
  let updatedMenu;
  let registerShops;
  let integrations;
  switch (action.type) {
    case types.GET_SHOP_DATA_FINISH:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case types.GET_CLAPP_CREDITS_FINISH:
      return {
        ...state,
        clappCredits: parseInt(action.payload),
        isLoading: false,
      };
    case types.GET_CATEGORY_FINISH:
      return {
        ...state,
        category: action.payload,
      };
    case types.GET_PROFILE_INFLUENCER_DATA_FINISH:
      return {
        ...state,
        profileInfluencer: {
          ...action.payload,
        },
        isLoading: false,
      };
    case types.GET_CURRENCY_SHOP_FINISH:
      return {
        ...state,
        currency: action.payload,
      };
    case types.GET_IS_ACTIVE_SHOP_FINISH:
      return {
        ...state,
        isActive: action.payload,
        isLoading: false,
      };
    case types.CHANGE_COUNTRY_SHOP:
      return {
        ...state,
        signUpCountry: action.payload,
      };
    case types.GET_IS_FREE_TRIAL:
      return {
        ...state,
        isFreeTrial: action.payload,
      };
    case types.GET_BUSINESS_ID:
      return {
        ...state,
        businessID: action.payload,
      };
    case types.CHANGE_IS_ACTIVE_SHOP:
      return {
        ...state,
        isActive: action.payload,
      };
    case types.SAVE_LEGAL_REPRESENTATIVE:
      return {
        ...state,
        legalRepresentative: { ...action.payload },
        isLoading: false,
      };
    case types.SAVE_COMPANY_INFO:
      return {
        ...state,
        ...action.payload?.data,
        shops: {
          ...state.shops,
          [action.payload.shopID]: {
            ...state.shops[action.payload.shopID],
            businessName: action.payload?.data?.businessName,
          },
        },
        isLoading: false,
      };
    case types.SAVE_PROFILE_INFLUENCER_INFO:
      return {
        ...state,
        profileInfluencer: { ...state.profileInfluencer, ...action.payload },
        isLoading: false,
      };
    case types.SAVE_SCHEDULE_INFO:
      return {
        ...state,
        schedule: [...action.payload],
        isLoading: false,
      };
    case types.SAVE_BILLING_DATA:
      return {
        ...state,
        billing: { ...action.payload },
        isLoading: false,
      };
    case types.UPDATE_CITIES_SHOP:
      return {
        ...state,
        cities: action.payload,
        isLoading: false,
      };
    case types.SAVE_STORES:
      return {
        ...state,
        stores: { ...state.stores, ...action.payload },
        isLoading: false,
      };
    case types.GET_LOGO_GENERATE_CONTENT:
      return {
        ...state,
        logoGenerateContent: action.payload,
      };
    case types.GET_SHOW_SOCIAL_FEED_GENERATOR:
      return {
        ...state,
        showSocialFeedGenerator: action.payload,
      };
    case types.UPDATE_STORE:
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeID]: { ...action.payload.data },
        },
      };
    case types.DELETE_STORE:
      delete state.stores[action.payload];
      return {
        ...state,
      };
    case types.CHANGE_IS_ACTIVE_STORE:
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeID]: {
            ...state.stores[action.payload.storeID],
            isActive: action.payload.isActive,
          },
        },
      };
    case types.SAVE_MENU_BY_STORE_ID:
      return {
        ...state,
        currentMenu: action.payload,
      };
    case types.CHANGE_ORDER_CATEGORIES:
      return {
        ...state,
        currentMenu: action.payload,
      };
    case types.CREATE_CATEGORY:
      return {
        ...state,
        currentMenu: action.payload,
      };
    case types.EDIT_CATEGORY:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.key].name = action.payload.name;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.DELETE_CATEGORY:
      return {
        ...state,
        currentMenu: action.payload,
      };
    case types.DUPLICATE_CATEGORY:
      return {
        ...state,
        currentMenu: action.payload,
      };
    case types.CHANGE_ORDER_PRODUCTS:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products = action.payload.products;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CREATE_PRODUCT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products = action.payload.products;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.EDIT_PRODUCT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ] = action.payload.data;

      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.DELETE_PRODUCT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products = action.payload.products;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.DUPLICATE_PRODUCT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products = action.payload.products;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CHANGE_STATUS_PRODUCT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].isActive = action.payload.isActive;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.MOVE_PRODUCT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products =
        action.payload.productsFrom;
      updatedMenu[action.payload.targetCategory].products =
        action.payload.productsTarget;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CREATE_ACCOMPANIMENT_TYPE:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options = action.payload.options;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.EDIT_ACCOMPANIMENT_TYPE:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options[action.payload.accompanimentTypeID] = {
        ...updatedMenu[action.payload.categoryID].products[
          action.payload.productID
        ].options[action.payload.accompanimentTypeID],
        ...action.payload.data,
      };
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.DELETE_ACCOMPANIMENT_TYPE:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options = action.payload.options;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.COPY_ACCOMPANIMENT_TYPE:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.targetCategory].products[
        action.payload.targetProduct
      ].options = action.payload.options;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CHANGE_ORDER_ACCOMPANIMENTS_TYPE:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options = action.payload.options;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CREATE_ACCOMPANIMENT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options[action.payload.accompanimentTypeID].options =
        action.payload.options;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.EDIT_ACCOMPANIMENT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options[action.payload.accompanimentTypeID].options[
        action.payload.accompanimentID
      ] = action.payload.data;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.DELETE_ACCOMPANIMENT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options[action.payload.accompanimentTypeID].options =
        action.payload.options;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CHANGE_STATUS_ACCOMPANIMENT:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options[action.payload.accompanimentTypeID].options[
        action.payload.accompaniment
      ].isActive = action.payload.status;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CHANGE_ORDER_ACCOMPANIMENTS:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options[action.payload.accompanimentsTypeID].options =
        action.payload.options;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.CHANGE_IS_MANDATORY:
      updatedMenu = [...state.currentMenu];
      updatedMenu[action.payload.categoryID].products[
        action.payload.productID
      ].options[action.payload.accompanimentTypeID].isMandatory =
        action.payload.isMandatory;
      return {
        ...state,
        currentMenu: updatedMenu,
      };
    case types.SAVE_THIRD_PARTY_CREDENTIALS:
      return {
        ...state,
        thirdPartyCredentials: {
          ...state.thirdPartyCredentials,
          ...action.payload,
        },
      };
    case types.SAVE_MSG_URBANOS:
      return {
        ...state,
        thirdPartyCredentials: {
          ...state.thirdPartyCredentials,
          msgUrbanos: {
            ...state.thirdPartyCredentials.msgUrbanos,
            ...action.payload,
          },
        },
      };
    case types.UPLOAD_MENU:
      return {
        ...state,
        currentMenu: action.payload,
      };

    case types.GET_ALL_SHOPS:
      return {
        ...state,
        shops: {
          ...state.shops,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.GET_SHOPS:
      return {
        ...state,
        shops: action.payload,
        isLoading: false,
      };
    case types.GET_NEXT_SHOPS:
      return {
        ...state,
        shops: {
          ...state.shops,
          ...action.payload,
        },
        isLoading: false,
      };
    case types.CLEAR_ALL_SHOPS:
      return {
        ...state,
        shops: {},
        isLoading: true,
      };
    case types.CHANGE_IS_ACTIVE_SHOP_BY_OWNER:
      return {
        ...state,
        shops: {
          ...state.shops,
          [action.payload.shopID]: {
            ...state.shops[action.payload.shopID],
            isActive: action.payload.status,
          },
        },
        isLoading: false,
      };
    case types.CHANGE_IS_PLAN_ACTIVE_SHOP_BY_OWNER:
      return {
        ...state,
        shops: {
          ...state.shops,
          [action.payload.shopID]: {
            ...state.shops[action.payload.shopID],
            isPlanActive: action.payload.status,
          },
        },
        isLoading: false,
      };
    case types.CHANGE_FEATURE_FLAG_SHOP_BY_OWNER:
      return {
        ...state,
        shops: {
          ...state?.shops,
          [action?.payload?.shopID]: {
            ...state?.shops[action?.payload?.shopID],
            featureFlags: {
              ...state?.shops[action?.payload?.shopID]?.featureFlags,
              [action?.payload?.featureFlag]: action?.payload?.status,
            },
          },
        },
        isLoading: false,
      };
    case types.CHANGE_IS_RENEW_PLAN_SHOP_BY_OWNER:
      return {
        ...state,
        shops: {
          ...state.shops,
          [action.payload.shopID]: {
            ...state.shops[action.payload.shopID],
            isRenewPlan: action.payload.status,
          },
        },
        isLoading: false,
      };
    case types.CREATE_SHOP:
      return {
        ...state,
        shops: {
          [action.payload.shopID]: action.payload.data,
          ...state.shops,
        },
      };
    case types.GET_ORDERS_METHODS:
      return {
        ...state,
        ordersMethods: action.payload,
        isLoading: false,
      };
    case types.GET_COST_COLLABORATION_SHOP:
      return {
        ...state,
        costCollaboration: action.payload,
        isLoading: false,
      };
    case types.SET_LAST_COMMENT_INVITE_SHOP:
      return {
        ...state,
        lastCommentInvite: action.payload,
        isLoading: false,
      };
    case types.SET_LAST_INVITE_CONFIGURATION_SHOP:
      return {
        ...state,
        lastInviteConfiguration: action.payload,
      };
    case types.SET_SHORT_DESCRIPTION_SHOP:
      return {
        ...state,
        shortDescription: action.payload,
        isLoading: false,
      };
    case types.SET_LAST_TYPE_CONTENT_INVITE_SHOP:
      return {
        ...state,
        lastTypeContent: action.payload,
        isLoading: false,
      };
    case types.SET_LAST_DATE_INVITE_SHOP:
      return {
        ...state,
        lastDateInvite: action.payload,
        isLoading: false,
      };
    case types.SET_BLOCKED_LAST_INVITE_DATE_SHOP:
      return {
        ...state,
        blockedLastInviteDate: action.payload,
        isLoading: false,
      };
    case types.SET_WEBSITE_URL_SHOP:
      return {
        ...state,
        websiteURL: action.payload,
      };
    case types.SET_CONTENT_REFERENCE_SHOP:
      return {
        ...state,
        profileInfluencer: {
          ...state.profileInfluencer,
          contentReference: action.payload,
        },
        isLoading: false,
      };
    case types.PAY_CLAPP_CREDITS:
      return {
        ...state,
        clappCredits:
          parseInt(state.clappCredits) + parseInt(action.payload.credits),
      };
    case types.GET_LOGO_SHOP:
      return {
        ...state,
        logoImg: action.payload,
      };
    case types.GET_BUSINESS_SHOP:
      return {
        ...state,
        businessName: action.payload,
      };
    case types.GET_PARENT_SHOP_ID:
      return {
        ...state,
        parentShopID: action.payload,
      };
    case types.SAVED_STATISTICS_CLOUD_TASK:
      return {
        ...state,
        statisticsCloudTaskID: action.payload,
      };
    case types.GET_SHOPS_APPLICANT:
      return {
        ...state,
        registerShops: action.payload,
      };
    case types.GET_NEXT_SHOPS_APPLICANT:
      return {
        ...state,
        registerShops: {
          ...state.registerShops,
          ...action.payload,
        },
      };
    case types.ACCEPT_SHOP_APPLICANT:
      registerShops = { ...state.registerShops };
      const shopApplicant = registerShops[action.payload.shopApplicantID];
      delete registerShops[action.payload.shopApplicantID];
      return {
        ...state,
        registerShops,
        shops: {
          [action.payload.shopID]: action.payload.shop,
          ...state.shops,
        },
        registerShopsAccepted: {
          [action.payload.shopApplicantID]: {
            ...shopApplicant,
            creationTime: action.payload?.shop?.creationTime,
            shopID: action.payload.shopID,
            plan: action.payload?.plan,
          },
          ...state.registerShopsAccepted,
        },
      };
    case types.REJECT_SHOP_APPLICANT:
      registerShops = { ...state.registerShops };
      delete registerShops[action.payload];
      return {
        ...state,
        registerShops,
      };
    case types.GET_SHOPS_ACCEPTED:
      return {
        ...state,
        registerShopsAccepted: action.payload,
      };
    case types.GET_NEXT_SHOPS_ACCEPTED:
      return {
        ...state,
        registerShopsAccepted: {
          ...state.registerShopsAccepted,
          ...action.payload,
        },
      };
    case types.GET_STAFF_LIST:
      return {
        ...state,
        staff: action.payload,
      };
    case types.ADD_STAFF:
      return {
        ...state,
        staff: [action.payload, ...state.staff],
      };
    case types.ASSIGN_STAFF_SHOP:
      const shops = { ...state.shops };
      return {
        ...state,
        shops: {
          ...shops,
          [action.payload.shopID]: {
            ...shops[action.payload.shopID],
            staffID: action.payload.staffID,
          },
        },
      };
    case types.GET_TERMS_PROFILE_INFLUENCER:
      return {
        ...state,
        profileInfluencer: {
          ...state.profileInfluencer,
          terms: action.payload,
        },
      };
    case types.GET_HAS_ADVANCED_CAMPAIGNS_PAYMENTS_SHOP:
      return {
        ...state,
        hasAdvancedCampaignPayments: action.payload,
      };
    case types.GET_AVERAGE_TICKET:
      return {
        ...state,
        averageTicket: action.payload,
      };
    case types.GET_FEEDBACKS:
      return {
        ...state,
        feedbacks: action.payload,
      };
    case types.NEXT_FEEDBACKS:
      return {
        ...state,
        feedbacks: { ...state.feedbacks, ...action.payload },
      };
    case types.GET_SIGN_UP_COUNTRY:
      return {
        ...state,
        signUpCountry: action.payload,
      };
    case types.SET_MIN_PRODUCT_VALUE:
      return {
        ...state,
        minProductValue: action.payload,
      };
    case types.GET_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
        isLoading: false,
      };
    case types.GET_TIKTOK_INTEGRATION:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          tiktok: action.payload,
        },
      };
    case types.REVOKE_TIKTOK_INTEGRATION:
      integrations = { ...state.integrations };
      delete integrations.tiktok;
      return {
        ...state,
        integrations: integrations,
      };
    case types.GET_IS_ACTIVE_SHOP_FIRST_TIME:
      return {
        ...state,
        isActiveFirstTime: action.payload,
      };
    case types.GET_FACEBOOK_INTEGRATION:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          facebook: {
            ...state.integrations.facebook,
            [action.payload.id]: action.payload,
          },
        },
        isLoading: false,
      };
    case types.REVOKE_FACEBOOK_INTEGRATION:
      integrations = { ...state.integrations?.facebook };

      delete integrations[action.payload.id];
      return {
        ...state,
        integrations: {
          ...state.integrations,
          facebook: integrations,
        },
        isLoading: false,
      };
    case types.GET_TIKTOK_BUSINESS_INTEGRATION:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          tiktokBusiness: action.payload,
        },
      };
    case types.REVOKE_TIKTOK_BUSINESS_INTEGRATION:
      integrations = { ...state.integrations };
      delete integrations.tiktokBusiness;
      return {
        ...state,
        integrations: integrations,
      };
    case types.GET_CANVA_INTEGRATION:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          canva: action.payload,
        },
      };
    case types.REVOKE_CANVA_INTEGRATION:
      integrations = { ...state.integrations };
      delete integrations.canva;
      return {
        ...state,
        integrations: integrations,
      };
    case types.GET_GENERAL_DISCOUNT_CODE:
      return {
        ...state,
        generalDiscountCode: action.payload,
      };
    case types.GET_GENERAL_DISCOUNT_VALUE:
      return {
        ...state,
        generalDiscountValue: action.payload,
      };
    case types.PAY_PACKAGE_PLAN:
      return {
        ...state,
        costCollaboration: action.payload.costCollaboration,
      };
    case types.GET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: { ...state.featureFlags, ...action.payload },
      };
    case types.LOADING_SHOPS_FINISH:
      return {
        ...state,
        isLoading: false,
      };
    case types.SET_LOADING_SHOPS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.GLOBAL_RESET_DUMMY_DATA:
      const { [DUMMY_STORE]: storeDeleted, ...stores } = state.stores;
      const currentMenu = [...state.currentMenu];
      currentMenu.shift();

      return {
        ...state,
        stores: stores,
        currentMenu: currentMenu,
      };
    case types.GLOBAL_RESET_STATE:
      return initialState;
    case types.GLOBAL_RESET_DATA_SHOP:
      return { ...initialState, shops: state.shops };

    default:
      return state;
  }
};

import { ref, deleteObject } from "firebase/storage";
import {
  ref as refDB,
  set,
  remove,
  get,
  update,
  push,
  query,
  orderByKey,
  startAfter,
  limitToFirst,
  onValue,
  orderByChild,
  startAt,
  endAt,
} from "firebase/database";
import { storage, db } from "../firebase";
import types from "../types";
import { SimpleAlert } from "../utils/alerts";
import {
  ALERT_ICON_TYPE_ERROR,
  ALERT_ICON_TYPE_SUCCESS,
  ALERT_ICON_TYPE_WARNING,
  INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY,
  JUSTO_STORE_TYPE,
  MAX_REFERENCE_PROFILES,
  OPERATOR_ROLE,
  ORDER_TYPE_STATISTICS,
  PAYMENT_MODEL_USE,
  PLAN_COLLABORATION,
  REGULAR_STORE_TYPE,
  ROLES_ADMIN_SHOPS,
  SHOPIFY_STORE_TYPE,
  SPECIAL_STORES,
  WORDPRESS_WOOCOMMERCE_STORE_TYPE,
} from "../utils/constants";
import axios from "../lib/axios";
import { renewToken } from "./auth";
import {
  startCreateSendAutomaticInvitesTask,
  startGetInvitesByShop,
} from "./invites";
import {
  capitalizeFirstLetter,
  deleteDuplicateElementsByArray,
  encryptPassword,
  formatURL,
  getObjectError,
} from "../utils/formats";
import { getRole, getShopID, getShopIsActive } from "./getters";
import { editApplicant, startDisableAllCampaigns } from "./campaigns";
import { DUMMY_STORE } from "../onboarding/stepsSelectors";
import { validationProfileInfluencer } from "../utils/influencers";
import i18next from "i18next";
import {
  ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED,
  COMPANY_INFORMATION_SAVED,
  ERROR,
  ERROR_DESCRIPTION_GENERIC,
  HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP,
  LEGAL_REPRESENTATIVE_SAVED,
  OPERATOR_USER_STORE,
  PLATFORMS_CONNECTED_INSTAGRAM_FAILED,
  PLATFORMS_TOKEN_EXPIRED,
  SCHEDULE_SAVED,
  SHOP_DEACTIVATE_ALERT,
  SHOP_DEACTIVATE_ALERT_DESCRIPTION,
  STORE_COVERAGE_RADIUS_REQUIRED,
  STORE_MENU_NO_HAS_CATEGORY_AND_PRODUCT,
  STORE_NO_HAS_MENU,
  TOKEN_EXPIRED,
  WARNING,
} from "../locales/keysTranslations";
import { payClappCredits } from "./payments";
import {
  formatAgesRanges,
  validMenuIsRequiredForStore,
} from "../services/shops";
import { editOrder } from "./orders";
import { endOfDay, startOfDay } from "date-fns";
import {
  setShowBackdrop,
  setShowModalOnboardingRequiredInformation,
} from "./ui";
import { startGetInfluencer } from "./influencers";
import ListErrorsActiveShop from "../components/Common/Lists/ListErrorsActiveShop";
import { downloadContent } from "../utils/urls";

const url = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

export const saveUrl = (url, pathRTDB) => async () => {
  try {
    await set(refDB(db, pathRTDB), url);
  } catch (error) {
    console.log(error);
  }
};

export const removeImageStorageAndDB =
  (uid, pathStorage, pathRTDB, deleteRTDB) => async () => {
    try {
      const storageRef = ref(storage, `${pathStorage}${uid}`);
      await deleteObject(storageRef);
      if (pathRTDB && deleteRTDB) {
        const dbRef = refDB(db, pathRTDB);
        await remove(dbRef);
      }
      return true;
    } catch (error) {
      console.log(error);
    }
  };

export const removeNodeRTDB = (pathRTDB) => async () => {
  try {
    await set(refDB(db, pathRTDB), null);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const startUploadFileAws =
  (file, mime, path, withAuth = true) =>
  async (dispatch, getState) => {
    try {
      let token;
      if (withAuth) {
        token = await dispatch(renewToken());
      }
      const { data } = await axios({
        method: "post",
        data: { type: mime, path },
        url: `${url}/${
          withAuth ? "generateAwsUploadUrl" : "generateAwsUploadUrlWithoutAuth"
        }`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        await axios({
          method: "put",
          data: file,
          url: data.url,
          headers: {
            "Content-Type": mime,
          },
        });
        return data.url.split("?")[0];
      } else {
        return false;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
export const startUpdateFileAws =
  (file, mime, path) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { type: mime, path, file },
        url: `${url}/updateFileToAws`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.url;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
export const startDeleteFileAws =
  (id, pathStorage, pathRTDB, deleteRTDB) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { id, path: pathStorage },
        url: `${url}/deleteFileAws`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        if (pathRTDB && deleteRTDB) {
          const dbRef = refDB(db, pathRTDB);
          await remove(dbRef);
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
export const startDeleteFilesAws =
  (arrayKeysFilesAws) => async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { arrayKeysFiles: arrayKeysFilesAws },
        url: `${url}/deleteFilesAws`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.ok;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };

export const startGetShopsByShopIDs =
  ({ shopIDs, setNew = false }) =>
  async (dispatch, getState) => {
    try {
      const currentShops = Object.keys(getState().shop.shops);
      shopIDs = shopIDs.filter((item, index) => {
        return shopIDs.indexOf(item) === index;
      });

      const queriesShops = [];
      shopIDs.forEach((shopID) => {
        if (currentShops.includes(shopID)) return;
        queriesShops.push(get(refDB(db, `shops/${shopID}`)));
      });

      if (queriesShops.length === 0) return dispatch(getNextShops({}));

      const shopsSnapshot = await Promise.all(queriesShops);

      let shops = {};
      shopsSnapshot.forEach((shopSnapshot) => {
        shops = {
          ...shops,
          [shopSnapshot.key]: shopSnapshot.val(),
        };
      });
      setNew ? dispatch(getShops(shops)) : dispatch(getNextShops(shops));
      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };

export const getShopData =
  (shopID, onCallBack = () => {}) =>
  async (dispatch) => {
    try {
      const dbRef = refDB(db, `shops/${shopID}/`);
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        dispatch(saveShopData(snapshot.val()));
        onCallBack(snapshot.val());
        return snapshot.val();
      } else {
        dispatch(loadingFinish());
        onCallBack(snapshot.val());
        return false;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
const saveShopData = (data) => ({
  type: types.GET_SHOP_DATA_FINISH,
  payload: data,
});

export const startSaveCurrencyShop =
  (currency) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/currency`);

      await set(dbRef, currency);

      dispatch(getCurrencyShop(currency));

      return true;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
export const startGetCurrencyShop = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/currency`);
    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(getCurrencyShop(data));
      return data;
    }
  } catch (error) {
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    console.log(error);
    return false;
  }
};
const getCurrencyShop = (data) => ({
  type: types.GET_CURRENCY_SHOP_FINISH,
  payload: data,
});
export const startGetProfileInfluencerData =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/profileInfluencer`);
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        dispatch(getProfileInfluencerData(data));
        return data;
      } else {
        dispatch(loadingFinish());
        return false;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error);
      return false;
    }
  };
const getProfileInfluencerData = (data) => ({
  type: types.GET_PROFILE_INFLUENCER_DATA_FINISH,
  payload: data,
});

export const startSaveLegalRepresentative =
  (data) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/legalRepresentative`);
      await update(dbRef, data);
      dispatch(saveLegalRepresentative(data));
      SimpleAlert({
        title: i18next.t(LEGAL_REPRESENTATIVE_SAVED),
        icon: ALERT_ICON_TYPE_SUCCESS,
      });
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const saveLegalRepresentative = (data) => ({
  type: types.SAVE_LEGAL_REPRESENTATIVE,
  payload: data,
});

export const startSaveCompanyInfo = (data) => async (dispatch, getState) => {
  try {
    const statisticsCloudTaskID = getState().shop.statisticsCloudTaskID;
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/`);

    await update(dbRef, {
      businessName: data.businessName,
      averageTicket: data.averageTicket,
      cities: data.cities,
      socialNetwork: data.socialNetwork ? formatURL(data.socialNetwork) : null,
      secondSocialNetwork: data.secondSocialNetwork
        ? formatURL(data.secondSocialNetwork)
        : null,
      trafficURL: data.trafficURL ? formatURL(data.trafficURL) : null,
      websiteURL: formatURL(data.websiteURL),
      shortDescription: data.shortDescription,
      adminPhone: data.adminPhone,
      reservationURL: data.reservationURL,
      category: data.category,
      subCategories: data.subCategories,
      ordersMethods: data.ordersMethods,
    });
    dispatch(
      saveCompanyInfo({
        shopID,
        data,
      })
    );

    if (data.socialNetwork?.length > 0 && !statisticsCloudTaskID) {
      await dispatch(startSaveMonthlySocialNetworkStatistics());
    }

    SimpleAlert({
      title: i18next.t(COMPANY_INFORMATION_SAVED),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const saveCompanyInfo = (data) => ({
  type: types.SAVE_COMPANY_INFO,
  payload: data,
});

export const startSaveProfileInfluencerInfo =
  (data, createCloudTask = true) =>
  async (dispatch, getState) => {
    try {
      const hasCloudTask =
        getState()?.shop?.profileInfluencer?.hasCloudTask || false;
      const shopID = dispatch(getShopID());
      if (
        !data.hasCloudTask &&
        data.autoInvitationsIsActive &&
        createCloudTask &&
        !hasCloudTask
      ) {
        data = {
          ...data,
          hasCloudTask: true,
        };
        const response = await dispatch(startCreateSendAutomaticInvitesTask());
        if (!response) return;
      } else {
        data = {
          ...data,
          hasCloudTask,
        };
      }
      const updates = {};
      const { averageTicket, ...restData } = data;

      if (averageTicket)
        updates[`shops/${shopID}/averageTicket`] = averageTicket;

      Object.keys(restData).forEach((key) => {
        const data = restData[key];
        if (key === "ageRange" || key === "audienceAgeRange") {
          updates[`shops/${shopID}/profileInfluencer/${key}`] =
            formatAgesRanges({ ages: data, mode: "save" });
          return;
        }
        updates[`shops/${shopID}/profileInfluencer/${key}`] = data;
      });

      await update(refDB(db), updates);

      localStorage.removeItem(INFLUENCERS_SUGGESTED_LOCAL_STORAGE_KEY);

      dispatch(saveProfileInfluencerInfo(restData));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const saveProfileInfluencerInfo = (data) => ({
  type: types.SAVE_PROFILE_INFLUENCER_INFO,
  payload: data,
});
export const startSaveAutoInvitationsIsActive =
  (autoInvitationsIsActive = false) =>
  async (dispatch, getState) => {
    try {
      const hasCloudTask =
        getState()?.shop?.profileInfluencer?.hasCloudTask || false;
      const shopID = dispatch(getShopID());
      const updates = {};

      if (!hasCloudTask && autoInvitationsIsActive) {
        const response = await dispatch(startCreateSendAutomaticInvitesTask());
        if (!response) return false;

        updates[`shops/${shopID}/profileInfluencer/hasCloudTask`] = true;
      }

      updates[`shops/${shopID}/profileInfluencer/autoInvitationsIsActive`] =
        autoInvitationsIsActive;

      await update(refDB(db), updates);

      dispatch(
        saveProfileInfluencerInfo({
          hasCloudTask: true,
          autoInvitationsIsActive,
        })
      );

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startAddReferenceProfile =
  (userID) => async (dispatch, getState) => {
    try {
      let influencer = getState()?.influencers?.influencers[userID];

      if (!influencer) {
        influencer = await dispatch(startGetInfluencer(userID));
        if (!influencer) return false;
      }
      let referenceProfiles = await dispatch(startGetReferenceProfiles());

      if (!referenceProfiles) {
        referenceProfiles = [];
      }

      const found = referenceProfiles.find(
        (profile) => profile.userID === userID
      );

      if (found) return false;

      let newProfilesReference = [...referenceProfiles];

      if (referenceProfiles.length < MAX_REFERENCE_PROFILES) {
        newProfilesReference.push({
          accountName: influencer.mainAccountName,
          platform: influencer.mainPlatform,
          userID,
        });
      } else {
        newProfilesReference.shift();
        newProfilesReference.push({
          accountName: influencer.mainAccountName,
          platform: influencer.mainPlatform,
          userID,
        });
      }

      await dispatch(startSaveReferenceProfiles(newProfilesReference));

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
export const startSaveReferenceProfiles =
  (referenceProfiles = []) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      await set(
        refDB(db, `shops/${shopID}/profileInfluencer/referenceProfiles`),
        referenceProfiles
      );

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetReferenceProfiles = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const snapshot = await get(
      refDB(db, `shops/${shopID}/profileInfluencer/referenceProfiles`)
    );
    if (!snapshot.exists()) return false;
    return snapshot.val();
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetScheduleInfo = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/schedule`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(saveScheduleInfo(data));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
    return false;
  }
};
export const startSaveScheduleInfo = (data) => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/schedule`);
    await set(dbRef, data);
    dispatch(saveScheduleInfo(data));
    SimpleAlert({
      title: i18next.t(SCHEDULE_SAVED),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_SUCCESS,
    });
    return false;
  }
};
const saveScheduleInfo = (data) => ({
  type: types.SAVE_SCHEDULE_INFO,
  payload: data,
});

export const starGetBillingData = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/billing`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(saveBillingData(snapshot.val()));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startSaveBillingData = (shopID, data) => async (dispatch) => {
  try {
    const dbRef = refDB(db, `shops/${shopID}/billing`);
    await update(dbRef, data);
    dispatch(saveBillingData(data));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const saveBillingData = (data) => ({
  type: types.SAVE_BILLING_DATA,
  payload: data,
});

export const startGetStores = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/stores`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      dispatch(saveStores(data));
      return data;
    } else {
      dispatch(loadingFinish());
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startCreateStore =
  (shopID, data) => async (dispatch, getState) => {
    try {
      // const snapshot = await get(refDB(db, `shops/${shopID}/stores`));
      // const snapshotPlan = await get(
      //   refDB(db, `shops/${shopID}/subscriptionPlan`)
      // );
      // if (snapshot.exists()) {
      //   const stores = snapshot.val();
      //   const plan = await dispatch(startGetPlanInfo(snapshotPlan.val()));
      //   if (Object.keys(stores).length >= plan.benefits.points) {
      //     SimpleAlert({
      //       title: "No puede crear más tiendas",
      //       text: `No puede crear más de ${plan.benefits.points} tiendas con su plan actual`,
      //       icon: ALERT_ICON_TYPE_ERROR,
      //     });
      //     return;
      //   }
      // }
      const isOpenOnboarding = getState().onboarding.isOpen;
      const stores = getState().shop.stores;
      const currencyShopState = getState().shop.currency;

      const LOCALE = getState()?.locales?.locale;
      const SPECIAL_STORES = Object.keys(LOCALE.specialStores);

      const {
        doCreateDiscountShipping,
        specialAccessToken,
        isActiveIntegration,
        currency: currencyShop,
      } = data;
      delete data.currency;
      delete data.specialAccessToken;
      delete data.doCreateDiscountShipping;
      delete data.isActiveIntegration;

      const dbRef = refDB(db, `shops/${shopID}/stores`);
      let storeKey = push(dbRef).key;

      const updates = {};

      if (SPECIAL_STORES.includes(data.storeType)) {
        data = {
          ...data,
          isActive: data.storeType === SHOPIFY_STORE_TYPE,
          storeURL: formatURL(data.storeURL),
          address: null,
          lat: null,
          lng: null,
          city: null,
          ordersMethods: null,
          productAvailability: null,
          coverageRadius: null,
        };
        if (data.storeType === SHOPIFY_STORE_TYPE) {
          updates[`shopsIntegrations/${shopID}/${data.storeType}/accessToken`] =
            specialAccessToken;
        }
        if (
          data.storeType === SHOPIFY_STORE_TYPE ||
          data.storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE
        ) {
          updates[
            `shopsIntegrations/${shopID}/${data.storeType}/doCreateDiscountShipping`
          ] = doCreateDiscountShipping;
          updates[`shopsIntegrations/${shopID}/${data.storeType}/isActive`] =
            data.storeType === SHOPIFY_STORE_TYPE ? true : isActiveIntegration;
        }
      }

      updates[`shops/${shopID}/stores/${storeKey}`] = data;

      if (
        (Object.keys(stores).length === 0 && isOpenOnboarding) ||
        !isOpenOnboarding
      ) {
        await update(refDB(db), updates);
      } else {
        storeKey = DUMMY_STORE;
      }

      data = {
        [storeKey]: {
          ...data,
        },
      };

      if (!currencyShopState && currencyShop) {
        await dispatch(startSaveCurrencyShop(currencyShop));
      }

      dispatch(saveStores(data));

      if (
        (Object.keys(stores).length === 0 && isOpenOnboarding) ||
        !isOpenOnboarding
      ) {
        await dispatch(startUpdateCitiesShop());
        await dispatch(startUpdateOrdersMethods());
      }

      return { ok: true, key: storeKey, data: data[storeKey] };
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const saveStores = (data) => ({
  type: types.SAVE_STORES,
  payload: data,
});

const startUpdateCitiesShop = () => async (dispatch, getState) => {
  try {
    const stores = getState().shop.stores;
    const shopID = dispatch(getShopID());

    const allCities = [];
    Object.keys(stores).forEach((storeID) => {
      const store = stores[storeID];
      if (store?.cities) {
        allCities.push(...store.cities);
      }
    });
    const citiesShop = deleteDuplicateElementsByArray(allCities);
    await set(refDB(db, `shops/${shopID}/cities`), citiesShop);
    dispatch(updateCitiesShop(citiesShop));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const startUpdateOrdersMethods = () => async (dispatch, getState) => {
  try {
    const stores = getState().shop.stores;
    const shopID = dispatch(getShopID());
    const updates = {};
    const ordersMethodsShop = {
      delivery: false,
      inStore: false,
      [JUSTO_STORE_TYPE]: false,
      [SHOPIFY_STORE_TYPE]: false,
      [WORDPRESS_WOOCOMMERCE_STORE_TYPE]: false,
    };
    updates[`shops/${shopID}/${JUSTO_STORE_TYPE}URL`] = null;
    updates[`shops/${shopID}/${SHOPIFY_STORE_TYPE}URL`] = null;
    updates[`shops/${shopID}/${WORDPRESS_WOOCOMMERCE_STORE_TYPE}URL`] = null;
    Object.keys(stores).forEach((storeID) => {
      const store = stores[storeID];
      if (!store?.isActive) return;

      if (store.storeType === REGULAR_STORE_TYPE) {
        const ordersMethods = store.ordersMethods;
        Object.keys(ordersMethods).forEach((orderMethod) => {
          if (ordersMethods[orderMethod]) {
            ordersMethodsShop[orderMethod] = true;
          }
        });
      } else {
        ordersMethodsShop[store.storeType] = true;
        updates[`shops/${shopID}/${store.storeType}URL`] = store.storeURL;
      }
    });
    updates[`shops/${shopID}/ordersMethods`] = ordersMethodsShop;
    await update(refDB(db), updates);
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

const updateCitiesShop = (data) => ({
  type: types.UPDATE_CITIES_SHOP,
  payload: data,
});

export const startUpdateStore =
  (shopID, storeID, data) => async (dispatch, getState) => {
    try {
      const currencyShopState = getState().shop.currency;
      const {
        specialAccessToken,
        doCreateDiscountShipping,
        isActiveIntegration,
        currency: currencyShop,
      } = data;
      delete data.currency;
      delete data.specialAccessToken;
      delete data.doCreateDiscountShipping;
      delete data.isActiveIntegration;

      const updates = {};

      const LOCALE = getState()?.locales?.locale;
      const SPECIAL_STORES = Object.keys(LOCALE.specialStores);

      if (SPECIAL_STORES.includes(data.storeType)) {
        data = {
          ...data,
          description: `Tienda especial de ${capitalizeFirstLetter(
            LOCALE.specialStores[data.storeType].value
          )}`,
          address: null,
          lat: null,
          lng: null,
          city: null,
          ordersMethods: null,
          productAvailability: null,
          coverageRadius: null,
        };
        if (data.storeType === SHOPIFY_STORE_TYPE) {
          if (specialAccessToken.length > 0) {
            updates[
              `shopsIntegrations/${shopID}/${data.storeType}/accessToken`
            ] = specialAccessToken;
          }
        }
        if (
          data.storeType === SHOPIFY_STORE_TYPE ||
          data.storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE
        ) {
          updates[
            `shopsIntegrations/${shopID}/${data.storeType}/doCreateDiscountShipping`
          ] = doCreateDiscountShipping;
          updates[`shopsIntegrations/${shopID}/${data.storeType}/isActive`] =
            isActiveIntegration;
        }
      }

      updates[`shops/${shopID}/stores/${storeID}`] = data;

      await update(refDB(db), updates);
      data = {
        storeID,
        data,
      };
      if (!currencyShopState && currencyShop) {
        await dispatch(startSaveCurrencyShop(currencyShop));
      }
      dispatch(updateStore(data));
      await dispatch(startUpdateCitiesShop());
      await dispatch(startUpdateOrdersMethods());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const updateStore = (data) => ({
  type: types.UPDATE_STORE,
  payload: data,
});

export const startDeleteStore = (storeID) => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const users = (await get(refDB(db, `shops/${shopID}/users`))).val();
    let hasUserOperator = false;
    Object.keys(users).forEach((userID) => {
      if (
        users[userID].role === OPERATOR_ROLE &&
        users[userID].storeID === storeID
      )
        return (hasUserOperator = true);
    });
    if (hasUserOperator) {
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(OPERATOR_USER_STORE),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    const dbRef = refDB(db, `shops/${shopID}/stores/${storeID}`);
    await remove(dbRef);
    dispatch(deleteStore(storeID));
    await dispatch(startUpdateCitiesShop());
    await dispatch(startUpdateOrdersMethods());
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const deleteStore = (data) => ({
  type: types.DELETE_STORE,
  payload: data,
});

export const startChangeIsActiveStore =
  (storeID, isActive, silentMode = false) =>
  async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const websiteURL = getState()?.shop?.websiteURL;

      const { stores } = getState().shop;
      const store = stores[storeID];

      if (isActive && store?.storeType === REGULAR_STORE_TYPE) {
        const isRequiredMenu = validMenuIsRequiredForStore({
          store,
          websiteURL,
        });

        if (isRequiredMenu) {
          const dbRefMenu = refDB(db, `menus/${shopID}/${storeID}`);
          const menuSnapshot = await get(dbRefMenu);
          if (!menuSnapshot.exists() && !silentMode) {
            SimpleAlert({
              title: i18next.t(ERROR),
              text: i18next.t(STORE_NO_HAS_MENU),
              icon: ALERT_ICON_TYPE_ERROR,
            });
            return false;
          }
          const categories = menuSnapshot.val();
          let hasCategories = false;
          let hasProducts = false;

          categories.map((category) => {
            hasCategories = true;
            const products = Object.keys(category.products ?? {});
            if (products.length >= 1) {
              hasProducts = true;
              return null;
            }
            return null;
          });
          if ((!hasCategories || !hasProducts) && !silentMode) {
            SimpleAlert({
              title: i18next.t(ERROR),
              text: i18next.t(STORE_MENU_NO_HAS_CATEGORY_AND_PRODUCT),
              icon: ALERT_ICON_TYPE_ERROR,
            });
            return false;
          }
          if (
            store.ordersMethods.delivery &&
            !store.isNational &&
            !store.coverageRadius &&
            !silentMode
          ) {
            SimpleAlert({
              title: i18next.t(ERROR),
              text: i18next.t(STORE_COVERAGE_RADIUS_REQUIRED),
              icon: ALERT_ICON_TYPE_ERROR,
            });
            return false;
          }
        }
      }
      let storesActive = 0;
      Object.keys(stores).map((keyStore) => {
        if (stores[keyStore].isActive) {
          return storesActive++;
        }
        return null;
      });
      const updates = {};
      if (storesActive <= 1 && !isActive) {
        updates[`shops/${shopID}/isActive`] = false;
      }
      updates[`shops/${shopID}/stores/${storeID}/isActive`] = isActive;
      await update(refDB(db), updates);
      const dataStore = {
        storeID,
        isActive,
      };
      dispatch(changeIsActiveStore(dataStore));

      if (!isActive) {
        storesActive <= 1 && dispatch(changeIsActiveShop(false));
      }

      dispatch(startUpdateOrdersMethods());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeIsActiveStore = (data) => ({
  type: types.CHANGE_IS_ACTIVE_STORE,
  payload: data,
});

export const startGetMenuByStoreId = (shopID, storeID) => async (dispatch) => {
  try {
    const dbRef = refDB(db, `menus/${shopID}/${storeID}/`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(saveMenuByStoreId(snapshot.val()));
      return true;
    } else {
      dispatch(saveMenuByStoreId([]));
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const saveMenuByStoreId = (data) => ({
  type: types.SAVE_MENU_BY_STORE_ID,
  payload: data,
});

export const startGetUrlForAds = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/stores`);
    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      const stores = snapshot.val();
      let storeUrl;
      Object.keys(stores || {}).forEach((storeID) => {
        const store = stores[storeID];

        if (SPECIAL_STORES.includes(store.storeType)) {
          storeUrl = store.storeURL;
        }
      });

      if (storeUrl) {
        return storeUrl;
      }
    }

    const snapshotWebsite = await get(
      refDB(db, `shops/${shopID}/websiwebsiteURLteURL`)
    );

    if (snapshotWebsite.exists()) {
      return snapshotWebsite.val();
    }

    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetThirdPartyCredentials = (shopID) => async (dispatch) => {
  try {
    const dbRef = refDB(db, `shops/${shopID}/thirdPartyCredentials`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(saveThirdPartyCredentials(snapshot.val()));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const saveThirdPartyCredentials = (data) => ({
  type: types.SAVE_THIRD_PARTY_CREDENTIALS,
  payload: data,
});

export const startSaveMsgUrbanos = (shopID, data) => async (dispatch) => {
  try {
    const dbRef = refDB(db, `shops/${shopID}/thirdPartyCredentials/msgUrbanos`);
    await update(dbRef, data);
    dispatch(saveMsgUrbanos(data));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const saveMsgUrbanos = (data) => ({
  type: types.SAVE_MSG_URBANOS,
  payload: data,
});

export const startGetIsActiveShop =
  (onReferenceAvailable) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/isActive`);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(getIsActiveShop(data));
      });
      onReferenceAvailable(dbRef);
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getIsActiveShop = (data) => ({
  type: types.GET_IS_ACTIVE_SHOP_FINISH,
  payload: data,
});

export const startValidationActivateDataShop =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const shop = getState().shop;
      const isActive = shop.isActive;
      const isActiveFirstTime = shop.isActiveFirstTime;

      if (isActiveFirstTime || isActive) {
        return true;
      }

      const dbRef = refDB(db, `shops/${shopID}/`);
      const snapshotShop = await get(dbRef);

      const shopData = snapshotShop.val();
      dispatch(saveShopData(shopData));

      const isValidData = dispatch(
        validateShopDataToActive(shopData, false, true)
      );

      return isValidData;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startChangeIsActiveShop =
  (isActive) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/`);

      if (!isActive) {
        await dispatch(startGetInvitesByShop());

        const invites = getState().invites.invites;
        const length = Object.keys(invites || {}).length;

        if (length > 0) {
          SimpleAlert({
            title: i18next.t(WARNING),
            text: i18next.t(HAS_INVITES_ACTIVE_FOR_DEACTIVATE_SHOP),
            icon: ALERT_ICON_TYPE_WARNING,
          });
          return false;
        }

        await update(dbRef, { isActive });
        await dispatch(startDisableAllCampaigns(shopID));
        dispatch(changeIsActiveShop(isActive));
        return true;
      }
      const snapshotShop = await get(dbRef);

      const shopData = snapshotShop.val();
      dispatch(saveShopData(shopData));

      const isValidData = dispatch(validateShopDataToActive(shopData));

      if (!isValidData) return false;

      const isActiveFirstTime = shopData?.isActiveFirstTime ?? true;

      await update(dbRef, { isActive });

      if (!isActiveFirstTime) {
        dispatch(startActiveShopFirstTime({ shopID }));
      }

      dispatch(changeIsActiveShop(isActive));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const changeIsActiveShop = (data) => ({
  type: types.CHANGE_IS_ACTIVE_SHOP,
  payload: data,
});
export const validateShopDataToActive =
  (shopData, openSimpleAlert = true, openOnboardingAlert = true) =>
  (dispatch, getState) => {
    const objectValidate = {
      businessName: shopData.businessName,
      category: shopData.category,
      subCategories: shopData.subCategories ?? [],
      shortDescription: shopData.shortDescription,
      schedule: shopData.schedule,
      ordersMethods: shopData.ordersMethods,
      logoImg: shopData.logoImg,
      coverImg: shopData.coverImg,
      stores: shopData.stores,
      isPlanActive: shopData.isPlanActive,
      defaultPaymentMethod: shopData?.stripe?.defaultPaymentMethod,
      hasDebt: shopData.hasDebt ?? false,
      subscriptionPlan: shopData?.subscriptionPlan || "",
      profileInfluencer: shopData?.profileInfluencer,
    };

    const isCampaignMode = shopData?.featureFlags?.isCampaignMode ?? false;

    const errorsToDisplay = [];

    Object.keys(objectValidate).forEach((key) => {
      if (
        (key === "businessName" ||
          key === "logoImg" ||
          key === "coverImg" ||
          key === "category" ||
          key === "subCategories" ||
          key === "shortDescription") &&
        (objectValidate[key]?.length === 0 || objectValidate[key] === undefined)
      ) {
        return errorsToDisplay.push(key);
      }
      if (key === "schedule" && !isCampaignMode) {
        let hasOneDayWithSchedule = false;
        const schedule = objectValidate[key] ?? [];
        schedule.map((day) => {
          if (day.turns) {
            hasOneDayWithSchedule = true;
          }
          return null;
        });
        return !hasOneDayWithSchedule && errorsToDisplay.push(key);
      }
      if (key === "ordersMethods" && !isCampaignMode) {
        let hasOneMethod = false;
        Object.keys(objectValidate[key] ?? {}).map((method) => {
          if (objectValidate[key][method] === true) {
            hasOneMethod = true;
          }
          return null;
        });
        return !hasOneMethod && errorsToDisplay.push(key);
      }

      if (key === "stores" && !isCampaignMode) {
        const stores = Object.keys(objectValidate[key] ?? {});

        if (stores.length === 0) return errorsToDisplay.push(key);
        let hasStoreActive = false;
        stores.map((storeKey) => {
          if (objectValidate[key][storeKey].isActive) {
            return (hasStoreActive = true);
          }
          return null;
        });

        return !hasStoreActive && errorsToDisplay.push(key);
      }
      if (key === "defaultPaymentMethod") {
        if (
          shopData.subscriptionPlan === PLAN_COLLABORATION &&
          !objectValidate[key] &&
          shopData.clappCredits === 0
        ) {
          return errorsToDisplay.push(key);
        }
      }
      if (key === "hasDebt") {
        if (objectValidate[key]) {
          return errorsToDisplay.push(key);
        }
      }
      if (key === "subscriptionPlan") {
        if (shopData.isFreeTrial === true) {
          return;
        }
        if (!objectValidate[key] || !shopData.isPlanActive) {
          return errorsToDisplay.push(key);
        }
      }
      if (key === "profileInfluencer") {
        if (
          !validationProfileInfluencer(objectValidate[key], "profileInfluencer")
        ) {
          errorsToDisplay.push("profileInfluencer");
        }
      }
    });
    if (errorsToDisplay.length > 0) {
      const isActiveFirstTime = shopData?.isActiveFirstTime;
      if (isActiveFirstTime && openSimpleAlert) {
        const theme = getState()?.ui?.theme;
        SimpleAlert({
          title: i18next.t(WARNING),
          text: i18next.t(ALERT_ACTIVATE_SHOP_FIELDS_REQUIRED),
          icon: ALERT_ICON_TYPE_WARNING,
          html: (
            <ListErrorsActiveShop
              errors={errorsToDisplay}
              themeSettings={theme}
            />
          ),
        });
      }
      if (!isActiveFirstTime && openOnboardingAlert) {
        dispatch(setShowModalOnboardingRequiredInformation(true));
      }
      return false;
    }
    return true;
  };

export const startGetLogoShop = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/logoImg`);
    const snapshop = await get(dbRef);
    const logo = snapshop.val() ?? null;
    dispatch(getLogoShop(logo));
    return logo;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getLogoShop = (data) => ({
  type: types.GET_LOGO_SHOP,
  payload: data,
});

export const startGetBusinessName = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    if (!shopID) return false;
    const dbRef = refDB(db, `shops/${shopID}/businessName`);
    const snapshop = await get(dbRef);
    const businessName = snapshop.val() ?? "";
    dispatch(getBusinessName(businessName));
    return businessName;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getBusinessName = (data) => ({
  type: types.GET_BUSINESS_SHOP,
  payload: data,
});
export const startGetBusinessNameByOwner =
  (shopID) => async (dispatch, getState) => {
    try {
      const dbRef = refDB(db, `shops/${shopID}/businessName`);
      const snapshop = await get(dbRef);
      const businessName = snapshop.val() ?? "";
      return businessName;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetAverageTicketShop = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/averageTicket`);
    const snapshop = await get(dbRef);
    const averageTicket = snapshop.val() ?? 0;
    dispatch(getAverageTicketShop(averageTicket));
    return averageTicket;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

const getAverageTicketShop = (data) => ({
  type: types.GET_AVERAGE_TICKET,
  payload: data,
});

export const startGetAllShops = (limit) => async (dispatch) => {
  try {
    const dbRef = refDB(db, "shops");
    const q = limit
      ? query(dbRef, orderByKey(), limitToFirst(limit))
      : query(dbRef, orderByKey());
    const snapshot = await get(q);
    if (snapshot.exists()) {
      const shops = snapshot.val();
      dispatch(getAllShops(shops));
    } else {
      dispatch(getAllShops({}));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetBusinessNameByShopIDs =
  ({ shopIDs }) =>
  async (dispatch, getState) => {
    try {
      const currentShops = Object.keys(getState().shop.shops);
      shopIDs = shopIDs.filter((item, index, arr) => {
        return arr.indexOf(item) === index && !currentShops.includes(item);
      });

      const queriesShops = [];
      shopIDs.forEach((shopID) => {
        queriesShops.push(get(refDB(db, `shops/${shopID}/businessName`)));
        queriesShops.push(get(refDB(db, `shops/${shopID}/logoImg`)));
      });
      const shopsSnapshot = await Promise.all(queriesShops);

      let shops = {};

      shopsSnapshot.forEach((shopSnapshot, index) => {
        const shopID = shopIDs[Math.floor(index / 2)];

        if (index % 2 === 0) {
          shops[shopID] = { businessName: shopSnapshot.val() };
        }

        if (index % 2 === 1) {
          shops[shopID] = { ...shops[shopID], logoImg: shopSnapshot.val() };
        }
      });

      dispatch(getAllShops(shops));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetBusinessNameAndStoresByShopIDs =
  ({ shopIDs }) =>
  async (dispatch, getState) => {
    try {
      const currentShops = Object.keys(getState().shop.shops);
      shopIDs = shopIDs.filter((item, index, arr) => {
        return arr.indexOf(item) === index && !currentShops.includes(item);
      });

      const queriesShopsBusinessName = [];
      shopIDs.forEach((shopID) => {
        queriesShopsBusinessName.push(
          get(refDB(db, `shops/${shopID}/businessName`))
        );
      });
      const shopsBusinessNameSnapshot = await Promise.all(
        queriesShopsBusinessName
      );
      let shops = {};
      shopsBusinessNameSnapshot.forEach((shopSnapshot, index) => {
        shops[shopIDs[index]] = { businessName: shopSnapshot.val() };
      });

      const queriesShopsStores = [];
      shopIDs.forEach((shopID) => {
        queriesShopsStores.push(get(refDB(db, `shops/${shopID}/stores`)));
      });
      const shopsStoresSnapshot = await Promise.all(queriesShopsStores);
      shopsStoresSnapshot.forEach((shopSnapshot, index) => {
        shops[shopIDs[index]].stores = shopSnapshot.val();
      });

      dispatch(getAllShops(shops));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetShopsByOwner =
  ({ lastKey, limit }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().auth.user.country;

      const dbRef = refDB(db, `shopsBySignUpCountry/${signUpCountry}`);
      const q = lastKey
        ? query(dbRef, orderByKey(), startAfter(lastKey), limitToFirst(limit))
        : query(dbRef, orderByKey(), limitToFirst(limit));

      const snapshot = await get(q);
      if (snapshot.exists()) {
        const shops = snapshot.val();
        const shopIDs = Object.keys(shops);
        dispatch(startGetShopsByShopIDs({ shopIDs, setNew: !lastKey }));
        return true;
      }
      dispatch(loadingFinish());
      return false;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const getShops = (data) => ({
  type: types.GET_SHOPS,
  payload: data,
});
const getNextShops = (data) => ({
  type: types.GET_NEXT_SHOPS,
  payload: data,
});
const getAllShops = (data) => ({
  type: types.GET_ALL_SHOPS,
  payload: data,
});

export const startGetShopsByName = (textQuery) => async (dispatch) => {
  try {
    const dbRef = refDB(db, "shops");
    const q = query(
      dbRef,
      orderByChild("businessName"),
      startAt(textQuery),
      endAt(textQuery + "\uf8ff")
    );
    const snapshop = await get(q);
    if (snapshop.exists()) {
      const shops = snapshop.val();
      dispatch(getAllShops(shops));
      return shops;
    }
    return {};
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGetShops = () => async (dispatch) => {
  try {
    const dbRef = refDB(db, "shops");
    const snapshop = await get(dbRef);
    if (snapshop.exists()) {
      const shops = snapshop.val();
      dispatch(getAllShops(shops));
      return shops;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGetBasicShopInfo = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const queries = [];
    queries.push(get(refDB(db, `shops/${shopID}/adminPhone`)));
    queries.push(get(refDB(db, `shops/${shopID}/businessName`)));
    const queriesSnapshot = await Promise.all(queries);

    let data = {};
    queriesSnapshot.forEach((querySnapshot) => {
      data = {
        ...data,
        [querySnapshot.key]: querySnapshot.val(),
      };
    });
    dispatch(saveShopData(data));
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetAcceptedTerms = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isAcceptedTerms`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGetShowSocialFeedGenerator = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/showSocialFeedGenerator`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(getShowSocialFeedGenerator(snapshot.val()));
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getShowSocialFeedGenerator = (data) => ({
  type: types.GET_SHOW_SOCIAL_FEED_GENERATOR,
  payload: data,
});
export const startAcceptTerms = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isAcceptedTerms`);
    await set(dbRef, true);
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetOrdersMethods = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/ordersMethods`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(getOrdersMethods(snapshot.val()));
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getOrdersMethods = (data) => ({
  type: types.GET_ORDERS_METHODS,
  payload: data,
});

export const startGetCategoryShop = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/category`);
    const snapshot = await get(dbRef);
    const category = snapshot.val() ?? "";
    dispatch(getCategoryShop(category));
    return category;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getCategoryShop = (data) => ({
  type: types.GET_CATEGORY_FINISH,
  payload: data,
});

export const startGetCostCollaborationShop =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/costCollaboration`);
      const snapshot = await get(dbRef);
      if (!snapshot.exists()) {
        return dispatch(getCostCollaborationShop(0));
      }
      return dispatch(getCostCollaborationShop(snapshot.val()));
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const getCostCollaborationShop = (data) => ({
  type: types.GET_COST_COLLABORATION_SHOP,
  payload: data,
});

export const startSaveLastCommentInvite =
  (comment) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/lastCommentInvite`);
      await set(dbRef, comment);
      dispatch(setLastCommentInviteShop(comment));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetLastCommentInvite = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/lastCommentInvite`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return false;
    dispatch(setLastCommentInviteShop(snapshot.val()));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const setLastCommentInviteShop = (data) => ({
  type: types.SET_LAST_COMMENT_INVITE_SHOP,
  payload: data,
});
export const startSaveLastTypeContent =
  (typeContent) => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/lastTypeContent`);
      await set(dbRef, typeContent);
      dispatch(setLastTypeContentInviteShop(typeContent));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetLastTypeContentInvite =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/lastTypeContent`);
      const snapshot = await get(dbRef);
      if (!snapshot.exists()) return false;
      dispatch(setLastTypeContentInviteShop(snapshot.val()));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const setLastTypeContentInviteShop = (data) => ({
  type: types.SET_LAST_TYPE_CONTENT_INVITE_SHOP,
  payload: data,
});
export const startSaveLastInviteConfiguration =
  (inviteConfiguration) => async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/lastInviteConfiguration`);
      await update(dbRef, inviteConfiguration);
      dispatch(setLastInviteConfiguration(inviteConfiguration));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetLastInviteConfiguration = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/lastInviteConfiguration`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return false;
    dispatch(setLastInviteConfiguration(snapshot.val()));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const setLastInviteConfiguration = (data) => ({
  type: types.SET_LAST_INVITE_CONFIGURATION_SHOP,
  payload: data,
});
export const startGetLastDateInvite = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/lastDateInvite`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return false;
    dispatch(setLastDateInviteShop(snapshot.val()));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const setLastDateInviteShop = (data) => ({
  type: types.SET_LAST_DATE_INVITE_SHOP,
  payload: data,
});
export const startGetWebsiteURLShop = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/websiteURL`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return false;
    dispatch(setWebsiteURLShop(snapshot.val()));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const setWebsiteURLShop = (data) => ({
  type: types.SET_WEBSITE_URL_SHOP,
  payload: data,
});
export const startGetShortDescriptionShop = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/shortDescription`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return false;
    dispatch(setShortDescriptionShop(snapshot.val()));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const setShortDescriptionShop = (data) => ({
  type: types.SET_SHORT_DESCRIPTION_SHOP,
  payload: data,
});
export const startGetBlockedLastInviteDate = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/blockedLastInviteDate`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) {
      dispatch(setBlockedLastInviteDateShop(""));
      return false;
    }
    dispatch(setBlockedLastInviteDateShop(snapshot.val()));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    dispatch(setBlockedLastInviteDateShop(""));
    return false;
  }
};
export const setBlockedLastInviteDateShop = (data) => ({
  type: types.SET_BLOCKED_LAST_INVITE_DATE_SHOP,
  payload: data,
});
export const startGetContentReference = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(
      db,
      `shops/${shopID}/profileInfluencer/contentReference`
    );
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) return false;
    dispatch(setContentReference(snapshot.val()));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const setContentReference = (data) => ({
  type: types.SET_CONTENT_REFERENCE_SHOP,
  payload: data,
});

export const startGetClappCredits = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/clappCredits`);
    const snapshot = await get(dbRef);
    const data = snapshot.val() ?? 0;
    dispatch(getClappCredits(data));
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getClappCredits = (data) => ({
  type: types.GET_CLAPP_CREDITS_FINISH,
  payload: data,
});

export const startGetParentShopID = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/parentShopID`);
    const snapshot = await get(dbRef);
    const data = snapshot.val() ?? "";
    dispatch(getParentShopID(data));
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getParentShopID = (data) => ({
  type: types.GET_PARENT_SHOP_ID,
  payload: data,
});

export const clearAllShops = () => ({
  type: types.CLEAR_ALL_SHOPS,
});
const loadingFinish = () => ({
  type: types.LOADING_SHOPS_FINISH,
});
export const setLoadingShops = (data) => ({
  type: types.SET_LOADING_SHOPS,
  payload: data,
});

export const validatePlanShop = () => (dispatch, getState) => {
  try {
    const isActive = dispatch(getShopIsActive());
    if (!isActive) {
      SimpleAlert({
        title: i18next.t(SHOP_DEACTIVATE_ALERT),
        text: i18next.t(SHOP_DEACTIVATE_ALERT_DESCRIPTION),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetIsActiveShopFirstTime = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isActiveFirstTime`);
    const snapshot = await get(dbRef);
    if (!snapshot.exists()) {
      dispatch(getIsActiveShopFirstTime(false));
      return false;
    }
    dispatch(getIsActiveShopFirstTime(snapshot.val()));
    return snapshot.val();
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getIsActiveShopFirstTime = (data) => ({
  type: types.GET_IS_ACTIVE_SHOP_FIRST_TIME,
  payload: data,
});

export const startActiveShopFirstTime =
  ({ shopID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { shopID },
        url: `${url}/activeShopFirstTime`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        return data.ok;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      console.log(error?.response?.data?.message ?? error.message);
      return false;
    }
  };

export const startGetMonthlyReport =
  ({ shopID, startDate, endDate, report }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        data: { shopID, startDate, endDate, report },
        url: `${url}/getMonthlyReportShop`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      // SimpleAlert({
      //   title: i18next.t(ERROR),
      //   text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
      //     code: errorFormatted.code,
      //     message: errorFormatted.message,
      //   }),
      //   icon: ALERT_ICON_TYPE_ERROR,
      // });
      return errorFormatted;
    }
  };

export const startSaveMonthlySocialNetworkStatistics =
  () => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const shopID = dispatch(getShopID());
      const { data } = await axios({
        method: "post",
        data: { shopID },
        url: `${url}/saveMonthlySocialNetworkStatistics`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        dispatch(savedStatisticsCloudTask(true));
      }
      return data;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return errorFormatted;
    }
  };
const savedStatisticsCloudTask = (data) => ({
  type: types.SAVED_STATISTICS_CLOUD_TASK,
  payload: data,
});

export const startGetShopsApplicant =
  ({ limit, onChangeLastKey, lastKey }) =>
  async (dispatch, getState) => {
    try {
      const signUpCountry = getState().auth.user.country;
      let q;
      if (limit) {
        q = lastKey
          ? query(
              refDB(db, `shopsApplicantsBySignUpCountry/${signUpCountry}`),
              orderByChild("creationTime"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              refDB(db, `shopsApplicantsBySignUpCountry/${signUpCountry}`),
              orderByChild("creationTime"),
              limitToFirst(limit)
            );
      } else {
        q = query(
          refDB(db, `shopsApplicantsBySignUpCountry/${signUpCountry}`),
          orderByChild("creationTime")
        );
      }

      const snapshot = await get(q);

      if (snapshot.exists()) {
        const shops = snapshot.val();
        const shopIDs = Object.keys(shops).reverse();
        const newLastKey = shopIDs[shopIDs.length - 1];
        onChangeLastKey(shops[newLastKey].creationTime);
        await dispatch(
          startGetShopsApplicantByShopIDs({ shopIDs, setNew: !lastKey })
        );
      } else {
        lastKey
          ? dispatch(getNextShopsApplicant({}))
          : dispatch(getShopsApplicant({}));
      }
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetShopsApplicantByShopIDs =
  ({ shopIDs, setNew = false }) =>
  async (dispatch, getState) => {
    try {
      const currentShopsApplicants = Object.keys(getState().shop.registerShops);
      shopIDs = shopIDs.filter((item, index) => {
        return shopIDs.indexOf(item) === index;
      });
      const queriesShopsApplicant = [];

      shopIDs.forEach((shopID) => {
        if (currentShopsApplicants.includes(shopID)) return;
        queriesShopsApplicant.push(get(refDB(db, `shopsApplicants/${shopID}`)));
      });

      const shopsSnapshot = await Promise.all(queriesShopsApplicant);

      if (queriesShopsApplicant.length === 0)
        return dispatch(getNextShopsApplicant({}));

      let shops = {};
      shopsSnapshot.forEach((shopSnapshot) => {
        shops[shopSnapshot.key] = shopSnapshot.val();
      });

      setNew
        ? dispatch(getShopsApplicant(shops))
        : dispatch(getNextShopsApplicant(shops));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getShopsApplicant = (data) => ({
  type: types.GET_SHOPS_APPLICANT,
  payload: data,
});
const getNextShopsApplicant = (data) => ({
  type: types.GET_NEXT_SHOPS_APPLICANT,
  payload: data,
});

export const startGetShopsAccepted =
  ({ limit, onChangeLastKey, lastKey }) =>
  async (dispatch) => {
    try {
      let q;
      if (limit) {
        q = lastKey
          ? query(
              refDB(db, `shopsApplicantsAccepted`),
              orderByChild("creationTimeNegative"),
              startAfter(lastKey),
              limitToFirst(limit)
            )
          : query(
              refDB(db, `shopsApplicantsAccepted`),
              orderByChild("creationTimeNegative"),
              limitToFirst(limit)
            );
      } else {
        q = query(
          refDB(db, `shopsApplicantsAccepted`),
          orderByChild("creationTimeNegative")
        );
      }

      const snapshot = await get(q);

      if (!snapshot.exists()) {
        lastKey ? getNextShopsAccepted({}) : dispatch(getShopsAccepted({}));
        return true;
      }

      const shopsApplicants = snapshot.val();
      const shopIDsGet = [];

      const keys = Object.keys(shopsApplicants);

      keys.forEach((shopApplicantID) => {
        const shopApplicant = shopsApplicants[shopApplicantID];
        shopIDsGet.push(shopApplicant.shopID);
      });

      await dispatch(startGetShopsByShopIDs({ shopIDs: shopIDsGet }));

      if (!lastKey) {
        dispatch(getShopsAccepted(shopsApplicants));
      } else {
        dispatch(getNextShopsAccepted(shopsApplicants));
      }

      const keysSorted = keys.sort((a, b) => {
        const dateA = shopsApplicants[a].creationTime;
        const dateB = shopsApplicants[b].creationTime;
        if (!dateA && !dateB) return 0;
        return dateB - dateA;
      });
      const newLastKey = keysSorted[keysSorted.length - 1];
      onChangeLastKey(shopsApplicants[newLastKey].creationTimeNegative);

      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetShopsApplicantAcepptedByShopIDs =
  ({ shopIDs, setNew = false }) =>
  async (dispatch, getState) => {
    try {
      const currentShopsApplicants = getState().shop.registerShopsAccepted;

      const currentShopsApplicantsKeys = Object.keys(currentShopsApplicants);
      shopIDs = shopIDs.filter((item, index) => {
        return shopIDs.indexOf(item) === index;
      });

      const queriesShopsApplicantAccepted = [];

      shopIDs.forEach((shopID) => {
        if (currentShopsApplicantsKeys.includes(shopID)) return;
        queriesShopsApplicantAccepted.push(
          get(refDB(db, `shopsApplicantsAccepted/${shopID}`))
        );
      });
      const shopIDsGet = [];

      if (queriesShopsApplicantAccepted.length === 0) {
        currentShopsApplicantsKeys.forEach((shopApplicantID) => {
          const shopApplicant = currentShopsApplicants[shopApplicantID];
          shopIDsGet.push(shopApplicant.shopID);
        });
        await dispatch(startGetShopsByShopIDs({ shopIDs: shopIDsGet }));
        return dispatch(getNextShopsAccepted({}));
      }

      const shopsSnapshot = await Promise.all(queriesShopsApplicantAccepted);

      let shops = {};
      shopsSnapshot.forEach((shopSnapshot) => {
        const shop = shopSnapshot.val();
        shops[shopSnapshot.key] = shop;
        shopIDsGet.push(shop.shopID);
      });

      await dispatch(startGetShopsByShopIDs({ shopIDs: shopIDsGet }));

      setNew
        ? dispatch(getShopsAccepted(shops))
        : dispatch(getNextShopsAccepted(shops));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getShopsAccepted = (data) => ({
  type: types.GET_SHOPS_ACCEPTED,
  payload: data,
});
const getNextShopsAccepted = (data) => ({
  type: types.GET_NEXT_SHOPS_ACCEPTED,
  payload: data,
});

export const startGetShopsApplicantByName = (textQuery) => async (dispatch) => {
  try {
    const dbRef = refDB(db, "shopsApplicants");
    const q = query(
      dbRef,
      orderByChild("businessName"),
      startAt(textQuery),
      endAt(textQuery + "\uf8ff")
    );
    const snapshop = await get(q);
    if (snapshop.exists()) {
      const shops = snapshop.val();
      dispatch(getNextShopsApplicant(shops));
      return shops;
    }
    return {};
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startAcceptShopApplicant = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const response = await axios({
      method: "post",
      url: `${url}/acceptShopApplicant`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data.ok) {
      dispatch(
        acceptShopApplicant({
          shopID: response.data.shopID,
          userID: response.data.userID,
          shop: response.data.shop,
          user: response.data.user,
          shopApplicantID: data.shopApplicantID,
          plan:
            data.paymentModel === PAYMENT_MODEL_USE
              ? PLAN_COLLABORATION
              : data.isEnterprise
              ? "enterprise"
              : "regular",
        })
      );
    }
    return response?.data?.ok;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const acceptShopApplicant = (data) => ({
  type: types.ACCEPT_SHOP_APPLICANT,
  payload: data,
});
export const startGetShopsApplicantAcceptedByName =
  (textQuery) => async (dispatch) => {
    try {
      const dbRef = refDB(db, "shopsApplicantsAccepted");
      const q = query(
        dbRef,
        orderByChild("businessName"),
        startAt(textQuery),
        endAt(textQuery + "\uf8ff")
      );
      const snapshop = await get(q);
      if (snapshop.exists()) {
        const shops = snapshop.val();
        dispatch(getNextShopsAccepted(shops));
        return shops;
      }
      return {};
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startRejectShopApplicant =
  (shopApplicantID) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/rejectShopApplicant`,
        data: {
          shopApplicantID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.data?.ok) {
        dispatch(rejectShopApplicant(shopApplicantID));
        return true;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: response?.data?.message || "error",
          code: response?.data?.code || "error",
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const rejectShopApplicant = (data) => ({
  type: types.REJECT_SHOP_APPLICANT,
  payload: data,
});

export const startGetShopifyIntegration = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const dbRefDoCreateDiscountShipping = refDB(
      db,
      `shopsIntegrations/${shopID}/${SHOPIFY_STORE_TYPE}/doCreateDiscountShipping`
    );
    const snapshotDoCreateDiscountShipping = await get(
      dbRefDoCreateDiscountShipping
    );
    const dbRefIsActive = refDB(
      db,
      `shopsIntegrations/${shopID}/${SHOPIFY_STORE_TYPE}/isActive`
    );
    const snapshotIsActive = await get(dbRefIsActive);
    const data = {
      doCreateDiscountShipping: snapshotDoCreateDiscountShipping.val() || false,
      isActive: snapshotIsActive.val() ?? false,
    };
    return data;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startDisconnectShopifyIntegration =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const stores = getState().shop.stores;

      const storeIDShopify = Object.keys(stores).find(
        (storeID) => stores[storeID].storeType === SHOPIFY_STORE_TYPE
      );

      const storeIDsActive = Object.keys(stores)
        .filter((storeID) => stores[storeID].isActive)
        .filter((storeID) => storeID !== storeIDShopify);
      const updates = {};

      if (storeIDsActive.length === 0) {
        updates[`shops/${shopID}/isActive`] = false;
      }

      updates[`shops/${shopID}/stores/${storeIDShopify}`] = null;
      updates[`shopsIntegrations/${shopID}/${SHOPIFY_STORE_TYPE}`] = null;

      await update(refDB(db), updates);
      dispatch(deleteStore(storeIDShopify));
      await dispatch(startUpdateCitiesShop());
      await dispatch(startUpdateOrdersMethods());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetWordPressWoocommerceIntegration =
  () => async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());

      const dbRefDoCreateDiscountShipping = refDB(
        db,
        `shopsIntegrations/${shopID}/${WORDPRESS_WOOCOMMERCE_STORE_TYPE}/doCreateDiscountShipping`
      );
      const snapshotDoCreateDiscountShipping = await get(
        dbRefDoCreateDiscountShipping
      );

      const dbRefIsActive = refDB(
        db,
        `shopsIntegrations/${shopID}/${WORDPRESS_WOOCOMMERCE_STORE_TYPE}/isActive`
      );
      const snapshotIsActive = await get(dbRefIsActive);

      const data = {
        doCreateDiscountShipping:
          snapshotDoCreateDiscountShipping.val() || false,
        isActive: snapshotIsActive.val() || false,
      };

      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startDisconnectWooCommerceIntegration =
  () => async (dispatch, getState) => {
    try {
      const shopID = dispatch(getShopID());
      const stores = getState().shop.stores;

      const storeIDWooCommerce = Object.keys(stores).find(
        (storeID) =>
          stores[storeID].storeType === WORDPRESS_WOOCOMMERCE_STORE_TYPE
      );

      const storeIDsActive = Object.keys(stores)
        .filter((storeID) => stores[storeID].isActive)
        .filter((storeID) => storeID !== storeIDWooCommerce);
      const updates = {};

      if (storeIDsActive.length === 0) {
        updates[`shops/${shopID}/isActive`] = false;
      }

      updates[`shops/${shopID}/stores/${storeIDWooCommerce}`] = null;
      updates[
        `shopsIntegrations/${shopID}/${WORDPRESS_WOOCOMMERCE_STORE_TYPE}`
      ] = null;

      await update(refDB(db), updates);
      dispatch(deleteStore(storeIDWooCommerce));
      await dispatch(startUpdateCitiesShop());
      await dispatch(startUpdateOrdersMethods());
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetTermsProfileInfluencer =
  () => async (dispatch, getState) => {
    try {
      const terms = getState().shop.profileInfluencer.terms;
      if (terms) return terms;
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/profileInfluencer/terms`);
      const snapshop = await get(dbRef);
      if (snapshop.exists()) {
        const terms = snapshop.val();
        dispatch(getTermsProfileInfluencer(terms));
        return terms;
      }
      return "";
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const getTermsProfileInfluencer = (data) => ({
  type: types.GET_TERMS_PROFILE_INFLUENCER,
  payload: data,
});

export const startSetIsLoggedFirstTime = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isLoggedFirstTime`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) {
      await set(dbRef, true);
      return false;
    } else {
      const isLoggedFirstTime = snapshot.val();
      if (!isLoggedFirstTime) {
        await set(dbRef, true);
      }
      return isLoggedFirstTime;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetIsResetPassword = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isResetPassword`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) {
      return true;
    } else {
      const isResetPassword = snapshot.val();
      return isResetPassword;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGetIsFreeTrial = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isFreeTrial`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) {
      return null;
    } else {
      const isFreeTrial = snapshot.val();
      dispatch(getIsFreeTrial(isFreeTrial));
      return isFreeTrial;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getIsFreeTrial = (data) => ({
  type: types.GET_IS_FREE_TRIAL,
  payload: data,
});
export const startGetBusinessID = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/businessID`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) {
      return null;
    } else {
      const businessID = snapshot.val();
      dispatch(getBusinessID(businessID));
      return businessID;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getBusinessID = (data) => ({
  type: types.GET_BUSINESS_ID,
  payload: data,
});

export const startSetIsResetPassword = (value) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isResetPassword`);

    await set(dbRef, value);
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startSetIsOpenedOnboarding = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/isOpenedOnboarding`);

    const snapshot = await get(dbRef);

    if (!snapshot.exists()) {
      await set(dbRef, true);
      return true;
    } else {
      const isOpenedOnboarding = snapshot.val();
      if (!isOpenedOnboarding) {
        await set(dbRef, true);
      }
      return isOpenedOnboarding;
    }
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetHasAdvancedCampaignPaymentsShop =
  () => async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const dbRef = refDB(db, `shops/${shopID}/hasAdvancedCampaignPayments`);
      const snapshot = await get(dbRef);

      if (!snapshot.exists()) {
        dispatch(getHasAdvancedCampaignPayments(true));
        return true;
      }
      dispatch(getHasAdvancedCampaignPayments(snapshot.val()));
      return true;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

const getHasAdvancedCampaignPayments = (data) => ({
  type: types.GET_HAS_ADVANCED_CAMPAIGNS_PAYMENTS_SHOP,
  payload: data,
});

export const startGetSignUpCountryShop = () => async (dispatch, getState) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/signUpCountry`);
    const snapshop = await get(dbRef);
    if (snapshop.exists()) {
      const signUpCountry = snapshop.val();
      dispatch(getSignUpCountryShop(signUpCountry));
      return signUpCountry;
    }
    return "";
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getSignUpCountryShop = (data) => ({
  type: types.GET_SIGN_UP_COUNTRY,
  payload: data,
});

export const startConnectTiktok =
  ({ onChangeLoading, onChangePopupReference = () => {} }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/connectTiktok`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onChangeLoading(false);
      if (response.data.ok) {
        const width = 500;
        const height = 800;
        const title = `TikTok Auth`;
        const url = response.data.url;
        const popup = window.open(
          url,
          title,
          `width=${width},height=${height}`
        );
        onChangePopupReference(popup);
        return;
      }
      return false;
    } catch (error) {
      onChangeLoading(false);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGetTokenTiktok = (data) => async (dispatch) => {
  const withAuth = data?.withAuth ?? false;
  try {
    let headers = {};
    if (withAuth) {
      const token = await dispatch(renewToken());
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const { data: result } = await axios({
      method: "post",
      url: `${url}/getTokenTiktok`,
      data,
      headers,
    });

    if (result.ok) {
      if (withAuth) {
        dispatch(getTiktokIntegration(result.data));
      }
      return true;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    if (withAuth) {
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    return false;
  }
};
const getTiktokIntegration = (data) => ({
  type: types.GET_TIKTOK_INTEGRATION,
  payload: data,
});

export const startRevokeTiktokAccount = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${url}/revokeTiktokAccount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      dispatch(revokeTiktokIntegration());
      return true;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const revokeTiktokIntegration = (data) => ({
  type: types.REVOKE_TIKTOK_INTEGRATION,
  payload: data,
});

export const startGetCreatorConfigUpload = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${url}/getCreatorConfigUpload`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      return result.data;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startUploadVideoTiktok = (data) => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${url}/uploadVideoTiktok`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      return result.ok;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startGenerateVideoDescriptionTiktok =
  (orderID) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/generateVideoDescriptionTiktok`,
        data: { orderID },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.ok) {
        return result.data.description;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: result.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startGenerateCommentProfileInfluencer =
  ({
    ugcContentFormat,
    formats,
    typeContent,
    wantsSocialMediaPost,
    language,
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/generateCommentProfileInfluencer`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ugcContentFormat,
          formats,
          typeContent,
          wantsSocialMediaPost,
          language,
        },
      });
      if (data.ok) {
        return data.data;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: data.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetIntegrations = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/integrations`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const integrations = snapshot.val();
      dispatch(getIntegrations(integrations));
      return integrations;
    }
    return {};
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getIntegrations = (data) => ({
  type: types.GET_INTEGRATIONS,
  payload: data,
});

export const startAssignFreeTrial = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data } = await axios({
      method: "post",
      url: `${url}/assignFreeTrial`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data?.ok) {
      return true;
    }

    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startConnectShopify =
  ({ accessToken, storeURL }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/connectShopify`,
        // data: {
        //   shopifyToken: accessToken,
        //   shopifyStoreURL: storeURL,
        // },
        data: {
          shop: storeURL,
          from: "app",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.ok) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startConnectWordpressWoocommerce =
  (storeID) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/connectWordpressWoocommerce`,
        data: {
          storeID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.ok) {
        window.location.href = response.data.url;
        return;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      dispatch(startDeleteStore(storeID));
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startActiveShopifyIntegration =
  ({ code, shop }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/getTokenShopify`,
        data: {
          code,
          shop,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response?.data?.ok) {
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
            message: response?.data?.message,
            code: response?.data?.code,
          }),
          icon: ALERT_ICON_TYPE_ERROR,
        });
      }

      return response.data.ok;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startConnectFacebook =
  ({
    onChangeLoading = () => {},
    showGlobalLoading = false,
    onCallbackConnected = () => {},
  }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      if (showGlobalLoading) dispatch(setShowBackdrop(true));

      window.FB.login(
        function (response) {
          if (response.status === "unknown") {
            onChangeLoading(false);
            return;
          }
          if (response.status !== "connected" || !response.authResponse) {
            SimpleAlert({
              title: i18next.t(ERROR),
              message: i18next.t(PLATFORMS_CONNECTED_INSTAGRAM_FAILED),
              icon: ALERT_ICON_TYPE_ERROR,
            });
            onChangeLoading(false);
            return;
          }
          const { accessToken, userID } = response.authResponse;

          dispatch(
            startSaveTokenFacebook({
              data: {
                id: userID,
                accessToken,
                name: "",
              },
              showGlobalLoading,
              onChangeLoading,
              onCallbackConnected,
            })
          );
        },
        {
          config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
        }
      );
    } catch (error) {
      onChangeLoading(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));

      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startSaveTokenFacebook =
  ({
    data,
    showGlobalLoading = false,
    onChangeLoading = () => {},
    onCallbackConnected = () => {},
  }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());

      onChangeLoading(true);
      if (showGlobalLoading) dispatch(setShowBackdrop(true));

      const { data: result } = await axios({
        method: "post",
        url: `${url}/saveTokenFacebook`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      onChangeLoading(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));

      if (result.ok) {
        dispatch(saveFacebookIntegration(result.data));
        onCallbackConnected();
        return true;
      }
      return false;
    } catch (error) {
      onChangeLoading(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));

      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const saveFacebookIntegration = (data) => ({
  type: types.GET_FACEBOOK_INTEGRATION,
  payload: data,
});

export const startRevokeFacebookAccount =
  ({ showGlobalLoading = false, onChangeLoading = () => {}, id }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      if (showGlobalLoading) dispatch(setShowBackdrop(true));

      window.FB.getLoginStatus(async (response) => {
        if (response.session) {
          window.FB.logout(
            dispatch(
              startRevokeFacebookAccount({
                showGlobalLoading,
                onChangeLoading,
              })
            )
          );
        } else {
          const token = await dispatch(renewToken());
          const { data: result } = await axios({
            method: "post",
            url: `${url}/revokeFacebookAccount`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { id },
          });

          onChangeLoading(false);
          if (showGlobalLoading) dispatch(setShowBackdrop(false));

          if (result.ok) {
            dispatch(
              revokeFacebookIntegration({
                id,
              })
            );
            return true;
          }
          return false;
        }
      });
    } catch (error) {
      onChangeLoading(false);
      if (showGlobalLoading) dispatch(setShowBackdrop(false));

      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
const revokeFacebookIntegration = (data) => ({
  type: types.REVOKE_FACEBOOK_INTEGRATION,
  payload: data,
});

export const startGenerateContentDescriptionInstagram =
  (orderID) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/generateContentDescriptionInstagram`,
        data: { orderID },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.ok) {
        return result.data.description;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: result.message,
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startUploadContentInstagram =
  (data, callbackTokenExpired) => async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/uploadContentInstagram`,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.ok) {
        return result.ok;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      if (errorFormatted.code === TOKEN_EXPIRED) {
        callbackTokenExpired();
        SimpleAlert({
          title: i18next.t(ERROR),
          text: i18next.t(PLATFORMS_TOKEN_EXPIRED),
          icon: ALERT_ICON_TYPE_ERROR,
        });
        return false;
      }
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };
export const startBlockedShopForLastInvite =
  (type = "block") =>
  async (dispatch) => {
    try {
      const role = dispatch(getRole());

      if (!ROLES_ADMIN_SHOPS.includes(role)) return true;

      const shopID = dispatch(getShopID());
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/blockedShopForLastInvite`,
        data: {
          shopID,
          type,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.ok) {
        dispatch(
          setBlockedLastInviteDateShop(
            type === "block" ? new Date().getTime() : null
          )
        );
        return result.ok;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startAssignDissatisfactionCredit =
  (orderID) => async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/assignDissatisfactionCredit`,
        data: {
          shopID,
          orderID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.ok) {
        dispatch(
          payClappCredits({
            credits: 1,
          })
        );
        return result.ok;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);

      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetGeneralDiscountCode = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/generalDiscountCode`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(getGeneralDiscountCode(snapshot.val()));
      return snapshot.val();
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getGeneralDiscountCode = (data) => ({
  type: types.GET_GENERAL_DISCOUNT_CODE,
  payload: data,
});
export const startGetGeneralDiscountValue = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/generalDiscountValue`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(getGeneralDiscountValue(snapshot.val()));
      return snapshot.val();
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getGeneralDiscountValue = (data) => ({
  type: types.GET_GENERAL_DISCOUNT_VALUE,
  payload: data,
});

export const startGenerateSocialMediaContent =
  ({ language, logoURL, websiteURL, context, urlContent, metadata }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data } = await axios({
        method: "post",
        url: `${url}/generateSocialContentMedia`,
        data: {
          language,
          logoURL,
          context,
          websiteURL,
          urlContent,
          metadata,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data?.ok) {
        return data.data;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetLogoUrlGenerateContent = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/logoGenerateContent`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const logoURL = snapshot.val();
      dispatch(getLogoGenerateContent(logoURL));
      return logoURL;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startSaveLogoUrlGenerateContent = (url) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/logoGenerateContent`);
    await set(dbRef, url);
    dispatch(getLogoGenerateContent(url));
    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getLogoGenerateContent = (data) => ({
  type: types.GET_LOGO_GENERATE_CONTENT,
  payload: data,
});

export const startSaveScheduledContent = (data) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const updates = {};

    const contents = data.contents;
    const keys = [];
    contents.forEach((content) => {
      let key = content.key;

      if (!key) key = push(refDB(db, `scheduledContents/${shopID}`)).key;

      if (content.key) {
        Object.keys(content).forEach((keyValue) => {
          if (keyValue === "key") return;
          updates[`scheduledContents/${shopID}/${content.key}/${keyValue}`] =
            content[keyValue];
        });

        updates[`scheduledContents/${shopID}/${content.key}/language`] =
          data.language;
        updates[`scheduledContents/${shopID}/${content.key}/websiteURL`] =
          data.websiteURL;
      } else {
        delete content.key;

        updates[`scheduledContents/${shopID}/${key}`] = {
          ...content,
          key: null,
          language: data.language,
          websiteURL: data.websiteURL,
        };
      }
      keys.push(key);
    });
    await update(refDB(db), updates);
    return keys;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startUpdatePublicationStatistics =
  ({ id, type }) =>
  async (dispatch, getState) => {
    try {
      const token = await dispatch(renewToken());
      const dataEditLastUpdate = {
        key: id,
        data: {
          lastUpdateAnalytics: new Date().getTime(),
        },
      };
      dispatch(
        type === ORDER_TYPE_STATISTICS
          ? editOrder(dataEditLastUpdate)
          : editApplicant(dataEditLastUpdate)
      );

      const { data } = await axios({
        method: "post",
        data: { id, type },
        url: `${url}/updatePublicationStatisticsFromPhyllo`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.ok) {
        const currentData =
          type === ORDER_TYPE_STATISTICS
            ? getState()?.orders?.orders[id]
            : getState()?.campaigns?.applicants[id];
        const currentAnalytics = currentData?.analyticsData || {};

        const updateData = {
          key: id,
          data: {
            hasUpdatableStatistics: true,
            analyticsData: {
              ...currentAnalytics,
              ...(data?.data?.analytics || {}),
            },
          },
        };
        dispatch(
          type === ORDER_TYPE_STATISTICS
            ? editOrder(updateData)
            : editApplicant(updateData)
        );
      } else {
        const updateData = {
          key: id,
          data: {
            hasUpdatableStatistics: false,
          },
        };
        dispatch(
          type === ORDER_TYPE_STATISTICS
            ? editOrder(updateData)
            : editApplicant(updateData)
        );
      }
      return data;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      // SimpleAlert({
      //   title: i18next.t(ERROR),
      //   text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
      //     code: errorFormatted.code,
      //     message: errorFormatted.message,
      //   }),
      //   icon: ALERT_ICON_TYPE_ERROR,
      // });
      return errorFormatted;
    }
  };

export const startAddBrandToBusiness =
  ({ businessName, name, email, password }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const passwordEncrypted = encryptPassword(password);
      const { data } = await axios({
        method: "post",
        url: `${url}/addBrandToBusiness`,
        data: {
          businessName,
          name,
          email,
          password: passwordEncrypted,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data?.ok) {
        await dispatch(renewToken());
        return data.data.shopID;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          code: errorFormatted.code,
          message: errorFormatted.message,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetContentList = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `contentList/${shopID}`);

    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const contentList = snapshot.val();
      return contentList;
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startAddContentToList = (data) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const key = push(refDB(db, `contentList/${shopID}`)).key;

    const newData = {
      ...data,
      creationTime: new Date().getTime(),
      creationTimeNegative: -new Date().getTime(),
    };

    await set(refDB(db, `contentList/${shopID}/${key}`), newData);

    return { key, data: newData };
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startDeleteContentFromList = (key) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const dbRef = refDB(db, `contentList/${shopID}/${key}`);
    await remove(dbRef);

    return true;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startAddContentsToList = (data) => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());

    const updates = {};

    const returnData = {};

    data.forEach((item) => {
      const key = push(refDB(db, `contentList/${shopID}`)).key;
      const newData = {
        ...item,
        creationTime: new Date().getTime(),
        creationTimeNegative: -new Date().getTime(),
      };

      updates[`contentList/${shopID}/${key}`] = newData;
      returnData[key] = newData;
    });

    await update(refDB(db), updates);

    return returnData;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};

export const startGetScheduledContentsByDate =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    try {
      const shopID = dispatch(getShopID());

      const finalStartDate = startOfDay(new Date(startDate)).getTime();
      const finalEndDate = endOfDay(new Date(endDate)).getTime();

      const dbRef = query(
        refDB(db, `scheduledContents/${shopID}`),
        orderByChild("date"),
        startAt(finalStartDate),
        endAt(finalEndDate)
      );

      const snapshot = await get(dbRef);
      if (!snapshot.exists()) return false;

      const data = snapshot.val();

      const filteredKeys = Object.keys(data).filter((key) => {
        const scheduledContent = data[key];
        const scheduledFirstContentDate = scheduledContent.firstContentDate;
        if (
          scheduledFirstContentDate >= finalStartDate &&
          scheduledFirstContentDate <= finalEndDate
        )
          return false;
        return true;
      });

      if (filteredKeys.length === 0) return false;

      const returnData = {};

      filteredKeys.forEach((key) => {
        returnData[key] = data[key];
      });

      return returnData;
    } catch (error) {
      console.log(error);
      const errorFormatted = getObjectError(error);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startConnectTiktokBusiness =
  ({ onChangeLoading, onChangePopupReference = () => {} }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/connectTiktokBusiness`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onChangeLoading(false);
      if (response.data.ok) {
        const width = 500;
        const height = 700;
        const title = `TikTok Business Auth`;
        const url = response.data.url;
        const popup = window.open(
          url,
          title,
          `width=${width},height=${height}`
        );
        onChangePopupReference(popup);
        return;
      }
      return false;
    } catch (error) {
      onChangeLoading(false);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetTokenTiktokBusiness = (data) => async (dispatch) => {
  const withAuth = data?.withAuth ?? false;
  try {
    let headers = {};
    if (withAuth) {
      const token = await dispatch(renewToken());
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const { data: result } = await axios({
      method: "post",
      url: `${url}/getTokenTiktokBusiness`,
      data,
      headers,
    });

    if (result.ok) {
      if (withAuth) {
        dispatch(getTiktokBusinessIntegration(result.data));
      }
      return true;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    if (withAuth) {
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    return false;
  }
};
const getTiktokBusinessIntegration = (data) => ({
  type: types.GET_TIKTOK_BUSINESS_INTEGRATION,
  payload: data,
});

export const startRevokeTiktokBusinessAccount = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${url}/revokeTiktokBusinessAccount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      dispatch(revokeTiktokBusinessIntegration());
      return true;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const revokeTiktokBusinessIntegration = (data) => ({
  type: types.REVOKE_TIKTOK_BUSINESS_INTEGRATION,
  payload: data,
});

export const startGetFeatureFlags = () => async (dispatch) => {
  try {
    const shopID = dispatch(getShopID());
    const dbRef = refDB(db, `shops/${shopID}/featureFlags`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      dispatch(getFeatureFlags(snapshot.val()));
      return snapshot.val();
    }
    return false;
  } catch (error) {
    console.log(error);
    const errorFormatted = getObjectError(error);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        code: errorFormatted.code,
        message: errorFormatted.message,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const getFeatureFlags = (data) => ({
  type: types.GET_FEATURE_FLAGS,
  payload: data,
});

export const startGetAwsDownloadUrl = (urlValue) => async (dispatch) => {
  try {
    const ownBuckets = [
      process.env.REACT_APP_AWS_BUCKET_NAME,
      process.env.REACT_APP_AWS_BUCKET_NAME_STAGING,
      process.env.REACT_APP_AWS_BUCKET_ORDERS_NAME,
      process.env.REACT_APP_AWS_BUCKET_ORDERS_NAME_STAGING,
    ];

    const isOwnUrl = ownBuckets.find(
      (bucket) => urlValue.includes(bucket) && urlValue.includes("amazonaws")
    );

    if (!isOwnUrl) {
      await downloadContent(urlValue);
      return true;
    }

    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${url}/generateAwsDownloadUrl`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        url: urlValue,
      },
    });
    if (result.ok) {
      const { url: downloadUrl } = result?.data || {};
      const anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.click();
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    await downloadContent(urlValue);
    return false;
  }
};

export const startConnectCanva =
  ({ onChangeLoading, onChangePopupReference = () => {} }) =>
  async (dispatch) => {
    try {
      onChangeLoading(true);
      const token = await dispatch(renewToken());
      const response = await axios({
        method: "post",
        url: `${url}/connectCanva`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onChangeLoading(false);
      if (response.data.ok) {
        const width = 500;
        const height = 700;
        const title = `Canva Auth`;
        const url = response.data.url;
        const popup = window.open(
          url,
          title,
          `width=${width},height=${height}`
        );
        onChangePopupReference(popup);
        return;
      }
      return false;
    } catch (error) {
      onChangeLoading(false);
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

export const startGetTokenCanva = (data) => async (dispatch) => {
  const withAuth = data?.withAuth ?? false;
  try {
    let headers = {};
    if (withAuth) {
      const token = await dispatch(renewToken());
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    const { data: result } = await axios({
      method: "post",
      url: `${url}/getTokenCanva`,
      data,
      headers,
    });

    if (result.ok) {
      if (withAuth) {
        dispatch(getCanvaIntegration(result.data));
      }
      return true;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    if (withAuth) {
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
    }
    return false;
  }
};
const getCanvaIntegration = (data) => ({
  type: types.GET_CANVA_INTEGRATION,
  payload: data,
});

export const startRevokeCanvaAccount = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${url}/revokeCanva`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      dispatch(revokeCanvaIntegration());
      return true;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
const revokeCanvaIntegration = (data) => ({
  type: types.REVOKE_CANVA_INTEGRATION,
  payload: data,
});

export const startCreateCanvaDesign = () => async (dispatch) => {
  try {
    const token = await dispatch(renewToken());
    const { data: result } = await axios({
      method: "post",
      url: `${url}/createCanvaDesign`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      return result.data;
    }
    return false;
  } catch (error) {
    const errorFormatted = getObjectError(error);
    console.log(errorFormatted);
    SimpleAlert({
      title: i18next.t(ERROR),
      text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
        message: errorFormatted.message,
        code: errorFormatted.code,
      }),
      icon: ALERT_ICON_TYPE_ERROR,
    });
    return false;
  }
};
export const startExportCanvaDesign =
  ({ designID }) =>
  async (dispatch) => {
    try {
      const token = await dispatch(renewToken());
      const { data: result } = await axios({
        method: "post",
        url: `${url}/exportCanvaDesign`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          designID,
        },
      });
      if (result.ok) {
        return result?.data;
      }
      return false;
    } catch (error) {
      const errorFormatted = getObjectError(error);
      console.log(errorFormatted);
      SimpleAlert({
        title: i18next.t(ERROR),
        text: i18next.t(ERROR_DESCRIPTION_GENERIC, {
          message: errorFormatted.message,
          code: errorFormatted.code,
        }),
        icon: ALERT_ICON_TYPE_ERROR,
      });
      return false;
    }
  };

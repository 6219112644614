import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { useDispatchApp, useSelectorApp } from "../../../../lib/redux";
import { useTranslationApp } from "../../../../lib/i18next";
import { useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { startRevokeCanvaAccount } from "../../../../actions/shops";
import Loader from "../../../Common/Loader/Loader";
import {
  ALERT_DESCRIPTION_DISCONNECT_CANVA,
  ARE_YOU_SURE,
  BUTTON_CONNECT_CANVA,
  BUTTON_DISCONNECT,
} from "../../../../locales/keysTranslations";
import { logoPlatform } from "../../../../utils/socialNetworks";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmAlert } from "../../../../utils/alerts";
import ButtonCanvaAction from "../../../Common/Buttons/ButtonCanvaAction";
import { CANVA } from "../../../../utils/constants";
import LinkText from "../../../Common/Texts/LinkText";

const CanvaSection = () => {
  const [isFetching, setIsFetching] = useState(false);
  const ref = useRef(null);

  const { isLoading: loadingShopData, integrations } = useSelectorApp(
    (state) => state.shop
  );

  const { t } = useTranslationApp();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const loading = loadingShopData;

  const canvaIntegration = integrations?.canva || {};

  const { isActive: isCanvaConnected = false } = canvaIntegration;

  const dispatch = useDispatchApp();

  const [params] = useSearchParams();

  const focusCanva = params.get("focusCanva");

  const onRevokeCanvaAccount = async () => {
    const result = await ConfirmAlert({
      title: t(ARE_YOU_SURE),
      text: t(ALERT_DESCRIPTION_DISCONNECT_CANVA),
      confirmButtonText: t(BUTTON_DISCONNECT),
    });
    if (!result.isConfirmed) return;

    setIsFetching(true);
    await dispatch(startRevokeCanvaAccount());
    setIsFetching(false);
  };

  useEffect(() => {
    if (focusCanva) {
      setTimeout(() => {
        window.document
          .querySelectorAll(".platforms-connect-section")[0]
          .scrollIntoView({
            inline: "nearest",
            behavior: "smooth",
            block: "center",
          });
      }, 300);
    }
    // eslint-disable-next-line
  }, [focusCanva]);

  if (loading) return <Loader />;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        mt: 2,
      }}
      ref={ref}
    >
      {isCanvaConnected ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            maxWidth: 320,
          }}
        >
          <Avatar
            alt={`Logo de ${CANVA}`}
            src={logoPlatform(CANVA)}
            variant="square"
            sx={{
              ".MuiAvatar-img": {
                objectFit: "contain",
              },
              mr: 1,
              height: {
                xs: 30,
                sm: 40,
              },
              width: {
                xs: 30,
                sm: 40,
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <LinkText
              variant={isMobile ? "body1" : "h6"}
              href={`https://www.canva.com/`}
              target="_blank"
              sx={{
                wordBreak: "break-all",
              }}
            >
              {canvaIntegration?.name}
            </LinkText>
            {isFetching ? (
              <Loader size={20} hasMarginTop={false} />
            ) : (
              <IconButton onClick={onRevokeCanvaAccount}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      ) : (
        <ButtonCanvaAction
          text={BUTTON_CONNECT_CANVA}
          sx={{
            width: "auto",
            minWidth: 230,
          }}
        />
      )}
    </Box>
  );
};

export default CanvaSection;

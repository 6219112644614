import React, { useEffect, useState } from "react";
import { CANVA, CANVA_SCOPES } from "../../../utils/constants";
import { useTranslationApp } from "../../../lib/i18next";
import { useDispatchApp, useSelectorApp } from "../../../lib/redux";
import { BUTTON_CREATE_WITH_CANVA } from "../../../locales/keysTranslations";
import LoadingButton from "./LoadingButton";
import { startConnectCanva, startGetTokenCanva } from "../../../actions/shops";
import { logoPlatform } from "../../../utils/socialNetworks";
import Avatar from "@mui/material/Avatar";
import { GRADIENT_CANVA, COLOR_CANVA } from "../../../utils/colors";

const ButtonCanvaAction = ({
  text = BUTTON_CREATE_WITH_CANVA,
  prevBlockClick = false,
  onPrevBlockClick = () => {},
  onClick = () => {},
  showGlobalLoading = false,
  sx = {},
  scope = CANVA_SCOPES,
  logo = CANVA,
  hasShadow = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [externalPopup, setExternalPopup] = useState(null);

  const { t } = useTranslationApp();
  const dispatch = useDispatchApp();
  const integrations = useSelectorApp((state) => state?.shop?.integrations);
  const showCanvaOption = integrations?.canva?.isActive;
  const currentScope = integrations?.canva?.scope || [];

  const hasDifferentScope = scope.some((s) => !currentScope.includes(s));

  const onGetTokenCanva = async ({ code, state }) => {
    setIsLoading(true);
    const response = await dispatch(
      startGetTokenCanva({ code, state, withAuth: true })
    );

    if (response) {
      await onClick();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      const currentUrl = externalPopup.location.href;
      if (!currentUrl) {
        return;
      }
      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get("code");
      const state = searchParams.get("state");

      if (code && state) {
        externalPopup.close();
        setExternalPopup(null);
        clearInterval(timer);

        onGetTokenCanva({
          code,
          state,
        });
      }
    }, 500);

    return () => {
      timer && clearInterval(timer);
    };
    // eslint-disable-next-line
  }, [externalPopup]);

  return (
    <LoadingButton
      loading={isLoading}
      color="primary"
      hasShadow={hasShadow}
      sx={{
        width: "100%",
        background: GRADIENT_CANVA,
        color: COLOR_CANVA,
        "&:hover": {
          background: GRADIENT_CANVA,
          color: COLOR_CANVA,
          filter: "brightness(0.9)",
        },
        ...sx,
      }}
      onClick={async (e) => {
        e.preventDefault();
        if (prevBlockClick) {
          onPrevBlockClick();
          return;
        }

        if (!showCanvaOption || hasDifferentScope) {
          dispatch(
            startConnectCanva({
              onChangeLoading: (loading) => setIsLoading(loading),
              onChangePopupReference: (popup) => setExternalPopup(popup),
            })
          );
          return;
        }

        setIsLoading(true);
        await onClick();
        setIsLoading(false);
      }}
    >
      <Avatar
        src={logoPlatform(logo)}
        variant="square"
        sx={{
          width: "20px",
          height: "20px",
          mr: 1,
          ".MuiAvatar-img": {
            objectFit: "contain",
          },
        }}
      />
      {t(text)}
    </LoadingButton>
  );
};

export default ButtonCanvaAction;

const types = {
  //LOCALE
  GET_LOCALES_LIST_FINISH: "[LOCALE] Get Locales List Finish",
  GET_LOCALE_FINISH: "[LOCALE] Get Locale Finish",
  SET_LOCALE_FINISH: "[LOCALE] Set Locale Finish",
  LOCALE_LOADING_FINISH: "[LOCALE] Locale Loading Finish",

  //AUTH
  AUTH_LOGIN: "[AUTH] Login",
  AUTH_LOGOUT: "[AUTH] Logout",
  AUTH_UPDATE_USER: "[AUTH] Update User",
  AUTH_LOADING_FINISH: "[AUTH] Loading finish",
  AUTH_RENEW_TOKEN: "[AUTH] Renew token",
  CHANGE_SHOP_SELECTED: "[AUTH] Change Shop Selected",
  CHANGE_COUNTRY_USER: "[AUTH] Change Country User",
  CHANGE_COUNTRY_SHOP: "[AUTH] Change Country Shop",
  CHANGE_STORE_SELECTED: "[AUTH] Change Store Selected",
  VERIFY_IS_ACTIVE_PLAN: "[AUTH] Verify Is Active Plan",
  SET_USER_FEEDBACK: "[AUTH] Set User Feedback",

  //ADVERTISERS
  GET_ADVERTISERS: "[ADVERTISERS] Get Advertisers",
  GET_NEXT_ADVERTISERS: "[ADVERTISERS] Get Next Advertisers",
  GET_ADVERTISER_USERS: "[ADVERTISERS] Get Advertiser Users",

  //ADS CAMPAIGNS
  GET_ADS_CAMPAIGNS_FINISH: "[ADS CAMPAIGNS] Get Ads Campaigns Finish",
  CLEAN_ADS_CAMPAIGNS: "[ADS CAMPAIGNS] Clean Ads Campaigns",
  SAVE_ADS_CAMPAIGN_FINISH: "[ADS CAMPAIGNS] Save Ads Campaign Finish",

  //UI
  UI_SET_ERROR: "[UI] Set Error",
  UI_CLEAR_ERROR: "[UI] Clear Error",
  UI_SET_OPEN_SLIDE_MENU: "[UI] Set Open Slide Menu",
  UI_SET_OPEN_MODAL_IMAGE: "[UI] Set Open Modal",
  UI_SET_ACTIVE_SOUND_DELIVERY: "[UI] Set Active Sound Delivery",
  UI_SET_OPEN_NOTIFICATION: "[UI] Set Open Notification",
  UI_SET_NEW_ORDER_IN_STORE: "[UI] Set New Order In Store",
  UI_SET_SHOW_BACKDROP: "[UI] Set Show Backdrop",
  UI_ENABLE_MENU_WITH_ACTION: "[UI] Enable Menu With Action",
  UI_DISABLE_MENU_WITH_ACTION: "[UI] Disable Menu With Action",
  SET_VERSION: "[UI] Set Version",
  RESET_UI_STATE: "[UI] Reset UI State",
  UI_SHOW_MODAL_BLOCK_LAST_INVITE: "[UI] Show Modal Block Last Invite",
  UI_SHOW_MODAL_SELECT_PACKAGE: "[UI] Show Modal Select Package",
  UI_SHOW_MODAL_ONBOARDING_REQUIRED_INFORMATION:
    "[UI] Show Modal Onboarding Required Information",
  UI_SET_THEME_BUSINESS: "[UI] Set Theme Business",
  UI_SET_SHOW_OPEN_CHAT_BUTTON: "[UI] Set Show Open Chat Button",

  //USERS
  USERS_CREATE_ADMIN_ACCOUNT: "[USERS] Create Admin Account",
  USERS_CREATE_ACCOUNTS_BY_ADMIN: "[USERS] Create Accounts By Admin",
  USERS_GET_ADMIN_ACCOUNTS_FINISH: "[USERS] Get Admin Accounts Finish",
  USERS_GET_NEXT_ADMIN_ACCOUNTS: "[USERS] Get Next Admin Accounts",
  EDIT_USER: "[USERS] Edit User",
  DELETE_USER: "[USERS] Delete User",
  USERS_GET_USERS_BY_SHOPID_FINISH: "[USERS] Get Users By ShopID Finish",
  USERS_GET_CONTACTS_BY_SHOPID_FINISH: "[USERS] Get Contacts By ShopID Finish",
  USERS_CREATE_CONTACT: "[USERS] Create Contact",
  USERS_EDIT_CONTACT: "[USERS] Edit Contact",
  USERS_DELETE_CONTACT: "[USERS] Delete Contact",
  USERS_LOADING_FINISH: "[USERS] Users Loading Finish",
  SET_USERS_LOADING: "[USERS] Set Users Loading",

  //BUSINESSES
  GET_BUSINESSES_FINISH: "[BUSINESSES] Get Businesses Finish",
  CREATE_BUSINESS: "[BUSINESSES] Create Business",

  //SHOPS
  UPLOAD_IMAGE: "[SHOPS] Upload Image",
  REMOVE_IMAGE: "[SHOPS] Remove Image",
  GET_SHOP_DATA_FINISH: "[SHOPS] Get Shop Data Finish",
  GET_CLAPP_CREDITS_FINISH: `[SHOPS] Get Credits Finish`,
  GET_CATEGORY_FINISH: "[SHOPS] Get Category Finish",
  GET_PROFILE_INFLUENCER_DATA_FINISH:
    "[SHOPS] Get Profile Influencer Data Finish",
  GET_CURRENCY_SHOP_FINISH: "[SHOPS] Get Currency Shop Finish",
  GET_IS_ACTIVE_SHOP_FINISH: "[SHOPS] Get Is Active Shop Finish",
  UPDATE_STORE: "[SHOPS] Update Cities Shop",
  CHANGE_IS_ACTIVE_SHOP: "[SHOPS] Change Is Active Shop",
  SAVE_LEGAL_REPRESENTATIVE: "[SHOPS] Save Legal Representative Information",
  SAVE_PROFILE_INFLUENCER_INFO: "[SHOPS] Save Profile Influencer Information",
  SAVE_COMPANY_INFO: "[SHOPS] Save Company Information",
  SAVE_SCHEDULE_INFO: "[SHOPS] Save Schedule Information",
  SAVE_BILLING_DATA: "[SHOPS] Save Billing Data",
  SAVE_STORES: "[SHOPS] Save Stores",
  UPDATE_CITIES_SHOP: "[SHOPS] Update Store",
  DELETE_STORE: "[SHOPS] Delete Store",
  CHANGE_IS_ACTIVE_STORE: "[SHOPS] Change Is Active Store",
  SAVE_MENU_BY_STORE_ID: "[SHOPS] Save Menu By Store ID",
  CHANGE_ORDER_CATEGORIES: "[SHOPS] Change Order Categories",
  CREATE_CATEGORY: "[SHOPS] Create Category",
  EDIT_CATEGORY: "[SHOPS] Edit Category",
  DELETE_CATEGORY: "[SHOPS] Delete Category",
  DUPLICATE_CATEGORY: "[SHOPS] Duplicate Category",
  CHANGE_ORDER_PRODUCTS: "[SHOPS] Change Order Products",
  CREATE_PRODUCT: "[SHOPS] Create Product",
  EDIT_PRODUCT: "[SHOPS] Edit Product",
  DELETE_PRODUCT: "[SHOPS] Delete Product",
  DUPLICATE_PRODUCT: "[SHOPS] Duplicate Product",
  CHANGE_STATUS_PRODUCT: "[SHOPS] Change Status Product",
  MOVE_PRODUCT: "[SHOPS] Move Product",
  CREATE_ACCOMPANIMENT_TYPE: "[SHOPS] Create Accompaniment Type",
  EDIT_ACCOMPANIMENT_TYPE: "[SHOPS] Edit Accompaniment Type",
  DELETE_ACCOMPANIMENT_TYPE: "[SHOPS] Delete Accompaniment Type",
  COPY_ACCOMPANIMENT_TYPE: "[SHOPS] Copy Accompaniment Type",
  CHANGE_ORDER_ACCOMPANIMENTS_TYPE: "[SHOPS] Change Order Accompaniments Type",
  CREATE_ACCOMPANIMENT: "[SHOPS] Create Accompaniment",
  EDIT_ACCOMPANIMENT: "[SHOPS] Edit Accompaniment",
  DELETE_ACCOMPANIMENT: "[SHOPS] Delete Accompaniment",
  CHANGE_ORDER_ACCOMPANIMENTS: "[SHOPS] Change Order Accompaniments",
  CHANGE_STATUS_ACCOMPANIMENT: "[SHOPS] Change Status Accompaniment",
  CHANGE_IS_MANDATORY: "[SHOPS] Change Is Mandatory",
  SAVE_THIRD_PARTY_CREDENTIALS: "[SHOPS] Save Third Party Credentials",
  SAVE_MSG_URBANOS: "[SHOPS] Save Msg Urbanos",
  UPLOAD_MENU: "[SHOPS] Upload Menu",
  GET_ALL_SHOPS: "[SHOPS] Get All Shops",
  GET_SHOPS: "[SHOPS] Get Shops",
  GET_NEXT_SHOPS: "[SHOPS] Get Next Shops",
  CLEAR_ALL_SHOPS: "[SHOPS] Clear All Shops",
  GET_ORDERS_METHODS: "[SHOPS] Get Orders Methods",
  GET_IS_FREE_TRIAL: "[SHOPS] Get Is Free Trial",
  GET_BUSINESS_ID: "[SHOPS] Get BusinessID",
  GET_COST_COLLABORATION_SHOP: "[SHOPS] Get Cost Collaboration",
  SET_LAST_COMMENT_INVITE_SHOP: "[SHOPS] Set Last Comment Invite",
  SET_LAST_TYPE_CONTENT_INVITE_SHOP: "[SHOPS] Set Last Type Content Invite",
  SET_LAST_INVITE_CONFIGURATION_SHOP: "[SHOPS] Set Last Invite Configuration",
  SET_LAST_DATE_INVITE_SHOP: "[SHOPS] Set Last Date Invite",
  SET_WEBSITE_URL_SHOP: "[SHOPS] Set Website URL",
  SET_SHORT_DESCRIPTION_SHOP: "[SHOPS] Set Short Description",
  SET_BLOCKED_LAST_INVITE_DATE_SHOP: "[SHOPS] Set Blocked Last Invite Date",
  SET_CONTENT_REFERENCE_SHOP: "[SHOPS] Set Content Reference",
  GET_LOGO_GENERATE_CONTENT: "[SHOPS] Get Logo Generate Content",
  GET_LOGO_SHOP: "[SHOPS] Get Logo Shop",
  GET_BUSINESS_SHOP: "[SHOPS] Get Business Shop",
  GET_PARENT_SHOP_ID: "[SHOPS] Get Parent Shop ID",
  SAVED_STATISTICS_CLOUD_TASK: "[SHOPS] Saved Statistics Cloud Task",
  GET_SHOPS_APPLICANT: "[SHOPS] Get Shops Applicant",
  GET_NEXT_SHOPS_APPLICANT: "[SHOPS] Get Next Shops Applicant",
  GET_SHOW_SOCIAL_FEED_GENERATOR: "[SHOPS] Get Show Social Feed Generator",
  ACCEPT_SHOP_APPLICANT: "[SHOPS] Accept Shop Applicant",
  REJECT_SHOP_APPLICANT: "[SHOPS] Reject Shop Applicant",
  GET_SHOPS_ACCEPTED: "[SHOPS] Get Shops Accepted",
  GET_NEXT_SHOPS_ACCEPTED: "[SHOPS] Get Next Shops Accepted",
  GET_STAFF_LIST: "[SHOPS] Get Staff List",
  ADD_STAFF: "[SHOPS] Add Staff",
  ASSIGN_STAFF_SHOP: "[SHOPS] Assign Staff Shop",
  GET_TERMS_PROFILE_INFLUENCER: "[SHOPS] Get Terms Profile Influencer",
  GET_HAS_ADVANCED_CAMPAIGNS_PAYMENTS_SHOP:
    "[SHOPS] Get Has Advanced Campaigns Payments Shop",
  GET_AVERAGE_TICKET: "[SHOPS] Get Average Ticket",
  GET_FEEDBACKS: "[SHOPS] Get Feedbacks",
  NEXT_FEEDBACKS: "[SHOPS] Get Next Feedbacks",
  GET_SIGN_UP_COUNTRY: "[SHOPS] Get Sign Up Country",
  SET_MIN_PRODUCT_VALUE: "[SHOPS] Set Min Product Value",
  GET_IS_ACTIVE_SHOP_FIRST_TIME: "[SHOPS] Get Is Active Shop First Time",
  GET_GENERAL_DISCOUNT_CODE: "[SHOPS] Get General Discount Code",
  GET_GENERAL_DISCOUNT_VALUE: "[SHOPS] Get General Discount Value",
  SET_LOADING_SHOPS: "[SHOPS] Set Loading Shops",
  GET_INTEGRATIONS: "[SHOPS] Get Integrations",
  GET_TIKTOK_INTEGRATION: "[SHOPS] Get Tiktok Integration",
  REVOKE_TIKTOK_INTEGRATION: "[SHOPS] Revoke Tiktok Integration",
  GET_FACEBOOK_INTEGRATION: "[SHOPS] Get Facebook Integration",
  REVOKE_FACEBOOK_INTEGRATION: "[SHOPS] Revoke Facebook Integration",
  GET_TIKTOK_BUSINESS_INTEGRATION: "[SHOPS] Get Tiktok Business Integration",
  REVOKE_TIKTOK_BUSINESS_INTEGRATION:
    "[SHOPS] Revoke Tiktok Business Integration",
  GET_CANVA_INTEGRATION: "[SHOPS] Get Canva Integration",
  REVOKE_CANVA_INTEGRATION: "[SHOPS] Revoke Canva Integration",
  GET_FEATURE_FLAGS: "[SHOPS] Get Feature Flags",
  LOADING_SHOPS_FINISH: "[SHOPS] Loading Shops Finish",

  //INFLUENCERS
  DISCARD_INFLUENCER_DEFINITIVELY:
    "[INFLUENCERS] Discard Influencer Definitively",
  GET_INFLUENCERS_DISCARDED_DEFINITIVELY:
    "[INFLUENCERS] Get Influencers Discarded Definitively",
  GET_INFLUENCERS_FINISH: "[INFLUENCERS] Get Influencers Finish",
  GET_NEXT_INFLUENCERS_FINISH: "[INFLUENCERS] Get Next Influencers Finish",
  GET_NEXT_INFLUENCERS_EXTERNAL_FINISH:
    "[INFLUENCERS] Get Next Influencers External Finish",
  SAVE_STATISTICS_INFLUENCER: "[INFLUENCERS] Save Statistics Influencer",
  SAVE_INFLUENCER_INFORMATION: "[INFLUENCERS] Save Influencer Information",
  DELETE_INFLUENCER: "[INFLUENCERS] Delete Influencer",
  CHANGE_IS_HIDDEN_INFLUENCER: "[INFLUENCERS] Change Is Hidden Influencer",
  CLEAN_IS_SUGGESTED_INFLUENCERS:
    "[INFLUENCERS] Clean Is Suggested Influencers",
  SET_INFLUENCERS_FAVORITES: "[INFLUENCERS] Set Influencers Favorites",
  SAVE_INFLUENCER_FAVORITE: "[INFLUENCERS] Save Influencer Favorite",
  DELETE_INFLUENCER_FAVORITE: "[INFLUENCERS] Delete Influencer Favorite",
  GET_CUSTOM_TAGS_INFLUENCERS: "[INFLUENCERS] Get custom tags influencers",
  GET_PRE_REGISTERED_INFLUENCERS_PROCESS:
    "[INFLUENCERS] Get Pre Registered Influencers Process",
  DELETE_INFLUENCER_EXTERNAL: "[INFLUENCERS] Delete Influencer External",
  LOADING_INFLUENCERS_FINISH: "[INFLUENCERS] Loading Influencers Finish",

  //AMBASSADORS
  GET_AMBASSADORS_FINISH: "[AMBASSADORS] Get Ambassadors Finish",
  GET_NEXT_AMBASSADORS_FINISH: "[AMBASSADORS] Get Next Ambassadors Finish",
  CREATE_AMBASSADOR: "[AMBASSADORS] Create Ambassador Finish",
  EDIT_AMBASSADOR: "[AMBASSADORS] Edit Ambassador Finish",
  DELETE_AMBASSADOR: "[AMBASSADORS] Delete Ambassador Finish",
  ASSIGN_CHAT_ID_AMBASSADOR: "[AMBASSADORS] Assign Chat ID Ambassador",
  LOADING_AMBASSADORS_FINISH: "[AMBASSADORS] Loading Ambassadors Finish",

  //REQUESTS INFLUENCERS
  GET_REQUESTS_INFLUENCERS_FINISH:
    "[REQUESTS INFLUENCERS] Get Requests Influencers Finish",
  LOADING_REQUESTS_INFLUENCERS_FINISH:
    "[REQUESTS INFLUENCERS] Loading Requests Influencers Finish",
  MARK_CODE_SENT_REQUEST: "[REQUESTS INFLUENCERS] Mark Code Sent Request",
  ACCEPT_REQUEST_INFLUENCER: "[REQUESTS INFLUENCERS] Accept Request Influencer",
  REJECT_REQUEST_INFLUENCER: "[REQUESTS INFLUENCERS] Reject Request Influencer",

  //INVITES
  GET_INVITES_BY_SHOP_FINISH: "[INVITES] Get Invites By Shop Finish",
  GET_EVENT_INVITES_BY_SHOP_FINISH:
    "[INVITES] Get Events Invites By Shop Finish",
  GET_EVENT_INVITES_BY_SHOP_PAGINATION:
    "[INVITES] Get Event Invites By Shop Pagination",
  GET_INVITES_BY_SHOP_PAGINATION: "[INVITES] Get Invites By Shop Pagination",
  GET_INVITES_INACTIVE_BY_SHOP_FINISH:
    "[INVITES] Get Invites Inactive By Shop Finish",
  GET_INVITES_INACTIVE_BY_SHOP_PAGINATION:
    "[INVITES] Get Invites Inactive By Shop Pagination",
  ADD_INVITE_INACTIVE: "[INVITES] Add Invite Inactive",
  INVITE_INFLUENCER: "[INVITES] Invite Influencer",
  INVITE_EVENT_INFLUENCER: "[INVITES] Invite Event Influencer",
  LOADING_INVITES_FINISH: "[INVITES] Loading Invites Finish",
  INVITE_MULTIPLE_INFLUENCERS: "[INVITES] Invite Multiple Influencers",
  EDIT_INVITE: "[INVITES] Edit Invite",
  EDIT_EVENT_INVITE: "[INVITES] Edit Event Invite",
  DELETE_INVITE: "[INVITES] Delete Invite",
  DELETE_EVENT_INVITE: "[INVITES] Delete Event Invite",

  //EVENTS
  GET_EVENTS_BY_SHOP: "[EVENTS] Get Events By Shop",
  GET_EVENTS_NAME_BY_SHOP: "[EVENTS] Get Events Name By Shop",
  CREATE_EVENT: "[EVENTS] Create Event",
  SAVE_EVENT: "[EVENTS] Save Event",
  CHANGE_STATUS_EVENT: "[EVENTS] Change Status Event",
  LOADING_EVENTS_INIT: "[EVENTS] Loading Events Init",
  LOADING_EVENTS_FINISH: "[EVENTS] Loading Events Finish",

  //ORDERS
  GET_ORDERS_BY_SHOP_FINISH: "[ORDERS] Get Orders By Shop Finish",
  GET_ORDERS_FINISH: "[ORDERS] Get Orders Finish",
  GET_ORDERS_BY_STORE_FINISH: "[ORDERS] Get Orders By Store Finish",
  GET_ORDERS_BY_STORE_PAGINATION: "[ORDERS] Get Orders By Store Pagination",
  CREATE_ORDER: "[ORDERS] Create Order",
  EDIT_ORDER: "[ORDERS] Edit Order",
  REMOVE_ORDERS: "[ORDERS] Remove Orders",
  ASSIGN_CHAT_ID_ORDER: "[ORDERS] Assign Chat ID Order",
  GENERAL_DISCOUNT_REDEMPTION: "[ORDERS] General Discount Redemption",
  GET_GENERAL_REDEMPTIONS_BY_STORE_FINISH:
    "[ORDERS] Get General Redemptions By Store Finish",
  GET_GENERAL_REDEMPTIONS_BY_STORE_PAGINATION:
    "[ORDERS] Get General Redemptions By Store Pagination",
  LOADING_ORDERS_FINISH: "[ORDERS] Loading Orders Finish",

  //REQUESTS
  GET_REQUESTS_BY_SHOP_FINISH: "[REQUESTS] Get Requests By Shop Finish",
  GET_REQUESTS_BY_SHOP_PAGINATION: "[REQUESTS] Get Requests By Shop Pagination",
  DELETE_REQUEST: "[REQUESTS] Delete Request",
  LOADING_REQUESTS_FINISH: "[REQUESTS] Loading Requests Finish",

  //PLANS AND PAYMENTS
  GET_PLAN_FINISH: "[PAYMENTS] Get Plan Finish",
  GET_SUBSCRIPTION_DATE_FINISH: "[PAYMENTS] Get Subscription Date Finish",
  GET_SUBSCRIPTION_PLAN_INFO_FINISH:
    "[PAYMENT] Get Subscription Plan Info Finish",
  GET_SUBSCRIPTION_PRO_FINISH: "[PAYMENT] Get Subscription Pro Finish",
  GET_PAYMENT_MODEL_FINISH: "[PAYMENTS] Get Payment Model Finish",
  GET_PLANS_FINISH: "[PAYMENTS] Get Plans Finish",
  SET_PLAN: "[PAYMENTS] Set Plan",
  GET_PAYMENTS_FINISH: "[PAYMENTS] Get Payments Finish",
  GET_NEXT_PAYMENTS_FINISH: "[PAYMENTS] Get Next Payments Finish",
  LOADING_PAYMENTS_FINISH: "[PAYMENTS] Loading Payments Finish",
  GET_STRIPE_INFO_FINISH: "[PAYMENTS] Get Stripe Info Finish",
  SET_DEFAULT_PAYMENT_METHOD: "[PAYMENTS] Get Default Payment Method Finish",
  SET_CUSTOMER_ID: "[PAYMENTS] Set Customer ID",
  SET_SUBSCRIPTION_ID: "[PAYMENTS] Set Subscription ID",
  SET_SUBSCRIPTION_END: "[PAYMENT] Set Subscription End",
  PAY_INVOICE: "[PAYMENT] Pay Invoice",
  PAY_CLAPP_CREDITS: `[PAYMENT] Pay Credits`,
  PAY_PACKAGE_PLAN: "[PAYMENT] Pay Package Plan",
  PAY_AMBASSADOR: "[PAYMENT] Pay Ambassador",
  PAY_INFLUENCER: "[PAYMENT] Pay Influencer",
  PAY_SUBSCRIPTION_PRO: "[PAYMENT] Pay Subscription Pro",
  GET_PAYMENTS_FAILED: "[PAYMENT] Get Payments Failed",
  GET_NEXT_PAYMENTS_FAILED: "[PAYMENT] Get Next Payments Failed",

  //DELIVERIES
  GET_ALL_DELIVERIES_FINISH: "[DELIVERIES] Get All Deliveries Finish",
  GET_ALL_SHOPS_DELIVERIES_FINISH:
    "[DELIVERIES] Get All Shops Deliveries Finish",
  GET_ALL_STORES_DELIVERIES_FINISH:
    "[DELIVERIES] Get All Stores Deliveries Finish",
  SET_HAS_OPEN_DELIVERY: "[DELIVERIES] Set Has Open Delivery",
  ACCEPT_DELIVERY: "[DELIVERIES] Accept Delivery",
  REJECT_DELIVERY: "[DELIVERIES] Reject Delivery",
  READY_DELIVERY: "[DELIVERIES] Ready Delivery",
  DELIVERED_DELIVERY: "[DELIVERIES] Delivered Delivery",
  DELETE_DELIVERY_OWNER: "[DELIVERIES] Delete Delivery Owner",
  LOADING_DELIVERIES_FINISH: "[DELIVERIES] Loading Deliveries Finish",

  //NOTIFICATIONS
  GET_ALL_NOTIFICATIONS_FINISH: "[NOTIFICATIONS] Get All Notifications Finish",
  GET_ALL_NOTIFICATIONS_PAGINATION_FINISH:
    "[NOTIFICATIONS] Get All Notifications Pagination Finish",
  UPDATE_IS_READ_NOTIFICATION: "[NOTIFICATIONS] Update Is Read Notification",

  //CAMPAIGNS
  GET_CAMPAIGNS_BY_SHOP_PAGINATION:
    "[CAMPAIGNS] Get Campaigns By Shop Pagination",
  CREATE_CAMPAIGN: "[CAMPAIGNS] Create Campaign",
  SAVE_CAMPAIGN: "[CAMPAIGNS] Save Campaign",
  FINISHED_CAMPAIGN: "[CAMPAIGNS] Finished Campaign",
  CHANGE_STATUS_CAMPAIGN: "[CAMPAIGNS] Change Status Campaign",
  GET_APPLICANTS_BY_CAMPAIGN_ID: "[CAMPAIGNS] Get Applications By Campaign ID",
  GET_APPLICANTS_BY_SHOP: "[CAMPAIGNS] Get Applications By Shop",
  GET_APPLICANTS_BY_PAGINATION: "[CAMPAIGNS] Get Applications By Pagination",
  EDIT_APPLICANT: "[CAMPAIGNS] Edit Applicant",
  ACCEPTED_APPLICANT: "[CAMPAIGNS] Accepted Applicant",
  INVITED_APPLICANT: "[CAMPAIGNS] Invited Applicant",
  REJECTED_APPLICANT: "[CAMPAIGNS] Rejected Applicant",
  ACCEPTED_CONTENT_APPLICANT: "[CAMPAIGNS] Accepted Content Applicant",
  REVISIONS_CONTENT_APPLICANT: "[CAMPAIGNS] Revisions Content Applicant",
  GET_INVITATIONS_CAMPAIGNS_BY_CAMPAIGN_FINISH:
    "[CAMPAIGNS] Get Invitations Campaigns By Campaign Finish",
  GET_INVITATIONS_CAMPAIGNS_BY_CAMPAIGN_NEXT_FINISH:
    "[CAMPAIGNS] Get Invitations Campaigns By Campaign Next Finish",
  INVITE_INFLUENCER_CAMPAIGN: "[CAMPAIGNS] Invite Influencer Campaign",
  DELETE_INVITE_INFLUENCER_CAMPAIGN:
    "[CAMPAIGNS] Delete Invite Influencer Campaign",
  GET_INVITATIONS_INFLUENCERS_EXTERNALS_BY_CAMPAIGN_FINISH:
    "[CAMPAIGNS] Get Invitations Externals By Campaign Finish",
  INVITE_INFLUENCER_EXTERNAL_CAMPAIGN:
    "[CAMPAIGNS] Invite Influencer External Campaign",
  CHANGE_STATUS_INVITE_INFLUENCER_EXTERNAL:
    "[CAMPAIGNS] Change Status Invite Influencer External",
  CLEAN_DATA_CAMPAIGN: "[CAMPAIGNS] Clean Data Campaign",
  ASSIGN_CHAT_ID_APPLICANT: "[APPLICANT] Assign Chat ID Applicant",
  DISABLE_ALL_CAMPAIGNS: "[CAMPAIGNS] Disable All Campaigns",
  LOADING_CAMPAIGNS_FINISH: "[CAMPAIGNS] Loading Campaigns Finish",

  //OWNER
  CREATE_SHOP: "[OWNER] Create Shop",
  CHANGE_FEATURE_FLAG_SHOP_BY_OWNER:
    "[OWNER] Change Feature Flag Shop By Owner",
  CHANGE_IS_ACTIVE_SHOP_BY_OWNER: "[OWNER] Change IsActive Shop By Owner",
  CHANGE_IS_PLAN_ACTIVE_SHOP_BY_OWNER:
    "[OWNER] Change IsPlanActive Shop By Owner",
  CHANGE_IS_RENEW_PLAN_SHOP_BY_OWNER:
    "[OWNER] Change IsRenewPlan Shop By Owner",
  CREATE_USER_ACCOUNT_BY_OWNER: "[OWNER] Create User Account By Owner",
  EDIT_USER_ACCOUNT_BY_OWNER: "[OWNER] Edit User Account By Owner",
  DELETE_USER_ACCOUNT_BY_OWNER: "[OWNER] Delete User Account By Owner",

  //CHAT
  OPEN_CHAT: "[CHAT] Open Chat",
  CLOSE_CHAT: "[CHAT] Close Chat",
  SET_CHATS: "[CHAT] Set Chats",
  SET_CURRENT_WRITE_MESSAGE: "[CHAT] Set Current Write Message",
  SET_CHATS_MESSAGES: "[CHAT] Set Chats Messages",
  SET_CURRENT_CHAT: "[CHAT] Set Current Chat",
  SET_CHAT_BOT_DEFAULT: "[CHAT] Set Chat Bot Default",
  SET_CURRENT_CHAT_MESSAGES: "[CHAT] Set Current Chat Messages",
  SET_IS_TYPING_BOT: "[CHAT] Set Is Typing Bot",
  SET_IS_OPEN_CHAT: "[CHAT] Set Is Open Chat",
  MARK_READ_CHAT: "[CHAT] Mark Read Chat",
  MARK_DISABLED_CHAT: "[CHAT] Mark Disabled Chat",
  SET_NEW_CHAT: "[CHAT] Set New Chat",
  SET_UPLOADING_FILE: "[CHAT] Set Uploading File",
  CLEAR_TEMPORARY_CHATS: "[CHAT] Clear Temporary Chats",
  ADD_MESSAGE_CHAT: "[CHAT] Add Message Chat",
  ADD_MESSAGES_CHAT: "[CHAT] Add Messages Chat",
  ADD_MESSAGE_CHAT_IN_LIST: "[CHAT] Add Message Chat In List",
  OPEN_AMBASSADOR_CONVERSATION: "[CHAT] Open Ambassador Conversation",
  OPEN_AMBASSADOR_NEW_CONVERSATION: "[CHAT] Open Ambassador New Conversation",
  OPEN_PUBLICATION_NEW_CONVERSATION: "[CHAT] Open Publication New Conversation",

  //ONBOARDING
  SET_OPEN_ONBOARDING: "[ONBOARDING] Set Open Onboarding",
  SET_STEPS_ONBOARDING: "[ONBOARDING] Set Steps Onboarding",
  SET_STEP_ONBOARDING: "[ONBOARDING] Set Step Onboarding",
  SET_IS_NEW_BRAND: "[ONBOARDING] Set Is New Brand Onboarding",
  CHANGE_SHOW_PREV_BUTTON: "[ONBOARDING] Change Show Prev Button",
  CHANGE_SHOW_NEXT_BUTTON: "[ONBOARDING] Change Show Next Button",
  CHANGE_SAVE_DATA_ONBOARDING: "[ONBOARDING] Change Save Data Onboarding",

  //GLOBAL TYPES
  GLOBAL_RESET_DATA_SHOP: "[GLOBAL] Reset Data Shop",
  GLOBAL_RESET_DATA_STORE: "[GLOBAL] Reset Data Store",
  GLOBAL_RESET_STATE: "[GLOBAL] Reset State",
  GLOBAL_RESET_DUMMY_DATA: "[GLOBAL] Reset Dummy Data",
};

export default types;
